import React, { createContext, useContext, ReactNode, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { useRouter } from 'next/router';
import Logger from '@/lib/clientLogger';
import { CLIENT_SIDE_ERROR_TAG } from '@/constants';
import { GtmEvent, GtmService } from '@/lib/GtmService';
import { getEnterpriseMember } from '@/__generated__/getEnterpriseMember';
import { GET_ENTERPRISE_MEMBER } from '@/components/request/GQL';

interface SiftScienceProviderProps {
  czenJSessionId?: string;
  children: ReactNode;
  oldConsoleEnabled: boolean;
  newConsoleEnabled: boolean;
}

interface SiftScienceContextProps {
  triggerEvent(): void;
}
const SiftScienceContext = createContext<SiftScienceContextProps>({
  triggerEvent: () => {},
});

export const SiftScienceContextProvider = ({
  czenJSessionId,
  oldConsoleEnabled,
  newConsoleEnabled,
  children,
}: SiftScienceProviderProps) => {
  const router = useRouter();

  const [enterpriseMember, { data }] = useLazyQuery<getEnterpriseMember>(GET_ENTERPRISE_MEMBER, {
    onError: (err) => {
      Logger.error({
        tags: [
          CLIENT_SIDE_ERROR_TAG,
          'SiftScienceContext Fetch error, failed to get enterprise member',
        ],
      });
      Sentry.captureException(err);
    },
  });

  const fetchMember = () => {
    enterpriseMember();
  };

  useEffect(() => {
    if (oldConsoleEnabled && (data?.getEnterpriseMember.member.externalId || czenJSessionId)) {
      const legacyData = {
        externalId: data?.getEnterpriseMember.member.externalId,
        sessionId: czenJSessionId,
      };
      GtmService.pushDefinedEvent(GtmEvent.SiftTrackPageViewLegacy, legacyData, {
        ignoreDuplicatesControl: true,
      });
    }

    if (newConsoleEnabled && data?.getEnterpriseMember.id) {
      const pageViewData = {
        memberUuid: data?.getEnterpriseMember.id,
      };
      GtmService.pushDefinedEvent(GtmEvent.SiftTrackPageView, pageViewData, {
        ignoreDuplicatesControl: true,
      });
    }
  }, [router.pathname, data]);

  return (
    <SiftScienceContext.Provider value={{ triggerEvent: fetchMember }}>
      {children}
    </SiftScienceContext.Provider>
  );
};
export const useSiftScienceTrigger = () => useContext(SiftScienceContext);
