import { ReactElement, useState, useEffect } from 'react';
import { useRouter } from 'next/router';

const usePageTransition = (children: ReactElement) => {
  const router = useRouter();
  const [isTransitionDisabled, setIsTransitionDisabled] = useState(false);

  useEffect(() => {
    router.beforePopState(() => {
      setIsTransitionDisabled(true);
      return true;
    });
  }, []);

  useEffect(() => {
    setIsTransitionDisabled(false);
  }, [children]);

  return { isTransitionDisabled, setIsTransitionDisabled };
};

export default usePageTransition;
