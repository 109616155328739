/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["draft"] }] */
import produce, { enableES5 } from 'immer';

import { EnterpriseAction } from '@/types/enterprise';
import { FlowAction } from '@/types/flow';
import { UserAction } from '@/types/user';
import { CURRENT_STATE_VERSION, CURRENT_STATE_MAJOR_VERSION } from '../constants';
import { AppAction, AppState } from '../types/app';

import { initialState as flowInitialState, reducer as flowReducer } from './flow/index';
import {
  initialState as EnterpriseInitialState,
  reducer as enterpriseReducer,
} from './enterprise/index';
import { initialState as UserInitialState, reducer as userReducer } from './user/index';

// immer uses Proxy, but if that's not available the ES5 plugin needs to be enabled
/* istanbul ignore if */
if (typeof Proxy === 'undefined') {
  // thanks, IE 11
  enableES5();
}

export const initialAppState: AppState = {
  flow: flowInitialState,
  enterprise: EnterpriseInitialState,
  user: UserInitialState,
  version: CURRENT_STATE_VERSION,
};

export const rootReducer = produce((draft: AppState, action: AppAction) => {
  draft.flow = flowReducer(draft.flow, action as FlowAction);
  draft.enterprise = enterpriseReducer(draft.enterprise, action as EnterpriseAction);
  draft.user = userReducer(draft.user, action as UserAction);
  return draft;
});

export function isStateVersionCompatible(state: AppState) {
  if (state?.version) {
    const versionComponents = state.version.split('.');
    if (versionComponents.length === 3 && versionComponents[0] === CURRENT_STATE_MAJOR_VERSION) {
      return true;
    }
  }
  return false;
}
