import { useContext } from 'react';
import {
  EnterpriseEnrollmentComponents,
  CustomizableComponent,
  CobrandLogo,
  ClientContactInformation,
} from '@/types/enterprise';
import { ClientCustomizationsContext } from '../ClientCustomizations';

type CustomFieldName = 'email' | 'employeeId' | 'roles';

const getField =
  (customizations: EnterpriseEnrollmentComponents | null) =>
  (name: CustomFieldName): CustomizableComponent | undefined =>
    customizations?.customizableComponents.find((component) => component.fieldName === name);

const getCobrandLogo = (cobrandLogo: CobrandLogo | null) => {
  return (
    cobrandLogo || {
      clickable: true,
      altText: null,
      imageURL: null,
    }
  );
};

const getClientContactInformation = (clientContactInformation: ClientContactInformation | null) => {
  return (
    clientContactInformation || {
      email: null,
      phoneNumber: null,
    }
  );
};

export default function useClientCustomizations() {
  const { customizations, clientContactInformation, cobrandLogo, customizedFooterContent } =
    useContext(ClientCustomizationsContext);

  return {
    customizations,
    getGroupShortName: () => customizations.groupShortName,
    getIsPasswordRequired: () => customizations.requirePassword,
    getField: getField(customizations),
    clientContactInformation: getClientContactInformation(clientContactInformation),
    cobrandLogo: getCobrandLogo(cobrandLogo),
    customizedFooterContent,
  };
}
