import { ApolloClient } from '@apollo/client';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { UserState } from '@/types/user';
import {
  getEnterpriseSSOPartnerEnrollmentDetails,
  getEnterpriseSSOPartnerEnrollmentDetailsVariables,
} from '@/__generated__/getEnterpriseSSOPartnerEnrollmentDetails';
import { GET_SSO_DATA } from '@/components/request/GQL';
import { DateTimeFormat } from '@/constants';
import useDirectEnrollmentData from '@/components/hooks/useDirectEnrollmentData';

export default function useSSOData(
  apolloClient: ApolloClient<any>,
  clientName: string,
  ssoPartnerInfoId?: string,
  isSSODirectRegistration?: boolean,
  isDirectEnrollmentRoute?: boolean
) {
  const [userState, setUserState] = useState<Partial<UserState> | null>(null);
  const [ssoError, setSSOError] = useState<boolean>(false);
  const { update } = useDirectEnrollmentData();
  useEffect(() => {
    async function fetchSSOData() {
      const { data, error } = await apolloClient.query<
        getEnterpriseSSOPartnerEnrollmentDetails,
        getEnterpriseSSOPartnerEnrollmentDetailsVariables
      >({
        query: GET_SSO_DATA,
        variables: {
          input: {
            clientName,
            ssoPartnerInfoId: ssoPartnerInfoId!,
          },
        },
      });

      if (error || !data?.getEnterpriseSSOPartnerEnrollmentDetails) {
        setSSOError(true);
      }

      if (data?.getEnterpriseSSOPartnerEnrollmentDetails) {
        const {
          firstName,
          lastName,
          employeeId,
          email,
          role,
          zipCode,
          dateOfBirth,
          addressLine1,
          city,
          state,
          careGroupInfo,
          lifeCareMemberId,
        } = data.getEnterpriseSSOPartnerEnrollmentDetails;
        setUserState({
          firstName: firstName || undefined,
          lastName: lastName || undefined,
          employeeId: employeeId || undefined,
          email: email || undefined,
          role: role || undefined,
          zipCode: zipCode || undefined,
          ssoPartnerInfoId,
        });

        if (ssoPartnerInfoId && isSSODirectRegistration) {
          update({
            groupShortName: careGroupInfo?.shortName || undefined,
            groupDisplayName: careGroupInfo?.name || undefined,
            firstName: firstName || undefined,
            lastName: lastName || undefined,
            employeeId: employeeId || undefined,
            email: email || undefined,
            dateOfBirth: dateOfBirth
              ? dayjs(dateOfBirth, DateTimeFormat.MONTH_DAY_YEAR)
              : undefined,
            address: addressLine1 || undefined,
            zipCode: zipCode || undefined,
            city: city || undefined,
            state: state || undefined,
            lifeCareMemberId: lifeCareMemberId || undefined,
          });
        }
      }
    }

    // skipping the graph call for direct enrollment routes as we are going to make the call directly in SSODirectRegistration page
    if (ssoPartnerInfoId && !isDirectEnrollmentRoute) {
      fetchSSOData();
    }
  }, [ssoPartnerInfoId]);

  return [userState, ssoError];
}
