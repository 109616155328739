import dayjs from 'dayjs';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { Config, CareAnalytics, EventPayload as GenericEventPayload } from '@care/analytics';
import { sendError } from '@/lib/ClientErrorHandler';
import { PAGE_VARIANT_DEFAULT, ROUTE_TO_SCREEN_NAME_MAP } from '@/constants';
import { getCzenVisitorId } from './czenCookiesHelper';

const { publicRuntimeConfig } = getConfig();
export const AMPLITUDE_DATE_FORMAT = 'MMDDYYYY';
export const AMPLITUDE_TIME_FORMAT = 'hh:mm A';

let careAnalytics: CareAnalytics;
let resolverFunction: Function;
const careAnalyticsResolver = new Promise((resolve) => {
  resolverFunction = resolve;
});

export type AmplitudeLogger = (payload: EventPayload) => void;

type UserFlow =
  | 'Enrollment'
  | 'Onboarding'
  | 'LC Transition'
  | 'Direct to Enrollment'
  | 'One-click enroll'
  | 'SSO'
  | 'Universal Landing Page';
type WebPlatform = 'Desktop' | 'Mobile';
/* eslint-disable camelcase */
interface StandardProperties {
  screen_name: string;
  user_flow: UserFlow;
  web_platform: WebPlatform;
  wps_group: string;
  visitor_id: string;
  url: string;
  event_id: string;
  event_time: string;
  user_id: string;
  czen_session_id: string;
  page_variant: string;
  member_uuid: string;
  screen_resolution: string;
  viewport_resolution: string;
  environment: string;
  referer: string;
}
interface EventProperties extends StandardProperties {
  [property: string]: string;
}
interface EventPayload extends GenericEventPayload {
  name: string;
  data?: Partial<EventProperties>;
}

async function init(
  isMobileInput: boolean,
  shortGroupName: string,
  environment: string,
  cscSessionId?: string
) {
  const commonData: Partial<StandardProperties> = {
    web_platform: isMobileInput ? 'Mobile' : 'Desktop',
    wps_group: shortGroupName,
    environment,
    referer: document?.referrer,
  };

  if (cscSessionId) {
    commonData.czen_session_id = cscSessionId;
  }
  const czenVisitorId = getCzenVisitorId();
  if (czenVisitorId) {
    commonData.visitor_id = czenVisitorId;
  }

  const careAnalyticsConfig: Config = {
    analyticsEnabled: publicRuntimeConfig.ANALYTICS_ENABLED === 'true',
    key: publicRuntimeConfig.AMPLITUDE_API_KEY,
    commonData,
  };
  careAnalytics = await import('@care/analytics');
  careAnalytics.init(careAnalyticsConfig);
  resolverFunction();
}

function logEvent(payload: EventPayload) {
  try {
    if (!careAnalytics) {
      careAnalyticsResolver.then(() => {
        careAnalytics.logEvent(payload);
      });
    } else {
      careAnalytics.logEvent(payload);
    }
  } catch (e: any) {
    sendError(e);
  }
}

function formatAmplitudeDate(date: dayjs.ConfigType | null) {
  if (date == null) return '';
  return dayjs(date).format(AMPLITUDE_DATE_FORMAT);
}
function formatAmplitudeTime(time: string) {
  const [hour, minute] = time.split(':');
  return dayjs().hour(Number(hour)).minute(Number(minute)).format(AMPLITUDE_TIME_FORMAT);
}

export function useEventLogger(rest?: Partial<StandardProperties>) {
  const router = useRouter();
  const screenName = ROUTE_TO_SCREEN_NAME_MAP[router.route];
  const page_variant = PAGE_VARIANT_DEFAULT;

  let url = '';
  let screenResolution = '';
  let viewportResolution = '';

  if (typeof window !== 'undefined') {
    url = window.document?.URL;
    screenResolution = `${window.screen.width} X ${window.screen.height}`;
    viewportResolution = `${window.innerWidth} X ${window.innerHeight}`;
  }

  const commonData: Partial<StandardProperties> = {
    url,
    screen_name: screenName,
    page_variant,
    screen_resolution: screenResolution,
    viewport_resolution: viewportResolution,
    ...rest,
  };

  const logger: AmplitudeLogger = (payload) => {
    const finalPayLoad: EventPayload = {
      name: payload.name,
      data: {
        ...commonData,
        ...payload.data,
      },
    };

    logEvent(finalPayLoad);
  };

  return logger;
}

export default { init, formatAmplitudeDate, formatAmplitudeTime };
