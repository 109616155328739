import { getEnterpriseEnrollmentComponents_getEnterpriseEnrollmentComponents_customizableComponents_roleOptions as roleOptions } from '@/__generated__/getEnterpriseEnrollmentComponents';

export interface UserState {
  ssoPartnerInfoId?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  googleIdToken?: string;
  employeeId?: string;
  zipCode?: string;
  role?: roleOptions;
}

export enum ActionTypes {
  UPDATE_USER = 'UPDATE_USER',
}

export type UserAction = { type: ActionTypes.UPDATE_USER; data: Partial<UserState> };
