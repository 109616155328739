/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["draft"] }] */

import { useEffect, useReducer, useCallback, Reducer } from 'react';
import { useLazyQuery } from '@apollo/client';
import produce from 'immer';

import { SKIP_AUTH_CONTEXT_KEY } from '../../../constants';
import { GET_ZIP_CODE_SUMMARY } from '../../request/GQL';
import logger from '../../../lib/clientLogger';
import { isValidZipCode } from '../../../utilities/globalValidations';
import {
  getZipcodeSummaryByZip,
  getZipcodeSummaryByZipVariables,
} from '../../../__generated__/getZipcodeSummaryByZip';

interface ZipState {
  city: string;
  state: string;
  zipcode: string;
  helperText: string;
  error: boolean;
}

const initialState: ZipState = {
  city: '',
  state: '',
  zipcode: '',
  helperText: '',
  error: false,
};

type ZipAction =
  | { type: 'setError'; message: string }
  | { type: 'setHelperText'; text: string }
  | { type: 'clearResult' }
  | { type: 'setResult'; city: string; state: string }
  | { type: 'setZipcode'; zipcode: string };

export const errorText = 'Enter a valid ZIP code (e.g. "02453")';

const zipReducer = produce((draft: ZipState, action: ZipAction): ZipState => {
  switch (action.type) {
    case 'setError':
      draft.error = true;
      draft.helperText = action.message;
      return draft;
    case 'setHelperText':
      draft.helperText = action.text;
      return draft;
    case 'clearResult':
      draft.error = false;
      draft.helperText = '';
      draft.city = '';
      draft.state = '';
      return draft;
    case 'setResult':
      draft.error = false;
      draft.helperText = `${action.city}, ${action.state}`;
      draft.city = action.city;
      draft.state = action.state;
      return draft;
    case 'setZipcode':
      draft.zipcode = action.zipcode;
      return draft;
    default:
      return draft;
  }
});

function useZipLocation(initialZipcode: string) {
  const [zipState, dispatch] = useReducer<Reducer<ZipState, ZipAction>>(zipReducer, {
    ...initialState,
    zipcode: initialZipcode,
  });
  const { city, state, zipcode, helperText, error } = zipState;

  const [getZipCodeSummary, { variables, loading, data, error: graphQLError }] = useLazyQuery<
    getZipcodeSummaryByZip,
    getZipcodeSummaryByZipVariables
  >(GET_ZIP_CODE_SUMMARY, {
    context: { [SKIP_AUTH_CONTEXT_KEY]: true },
  });
  const validateZipCode = useCallback(
    (showError: boolean) => {
      if (isValidZipCode(zipcode)) {
        getZipCodeSummary({
          variables: {
            zipcode,
          },
        });
      } else if (!isValidZipCode(zipcode) && showError) {
        dispatch({ type: 'setError', message: errorText });
      } else {
        dispatch({ type: 'setHelperText', text: '' });
      }
    },
    [dispatch, zipcode]
  );

  const setZipcode = useCallback(
    (zip: string) => {
      dispatch({ type: 'setZipcode', zipcode: zip });
    },
    [dispatch]
  );

  useEffect(() => {
    validateZipCode(false);
  }, [validateZipCode, zipcode]);

  useEffect(() => {
    if (!loading && data) {
      const { getZipcodeSummaryByZip: getZipcodeSummaryByZipData } = data;

      if (getZipcodeSummaryByZipData.__typename === 'ZipcodeSummary') {
        dispatch({
          type: 'setResult',
          city: getZipcodeSummaryByZipData.city,
          state: getZipcodeSummaryByZipData.state,
        });
      } else {
        dispatch({ type: 'setError', message: errorText });
      }
    }
    if (!loading && graphQLError) {
      logger.error({ event: 'getZipCodeSummaryError' });
      dispatch({ type: 'clearResult' });
    }
  }, [loading, data, variables, graphQLError]);

  return {
    city,
    state,
    zipcode,
    helperText,
    error,
    loading,
    validateZipCode,
    setZipcode,
  };
}

export default useZipLocation;
