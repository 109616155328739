import * as Sentry from '@sentry/react';
import { cloneDeepWith } from 'lodash-es';

interface ClientError {
  type: string;
  message: string;
  stack: string;
}

type LogLevel = 'info' | 'warn' | 'error';

interface LogObject {
  tags?: string[];
  error?: ClientError;
  [key: string]: any;
}

const endpoint = '/app/ent-enrollment/api/logs';

export interface NormalizedLogObject extends LogObject {
  level: LogLevel;
  message?: string;
}

function omitDeep(collection: LogObject, excludeKeys: any[]) {
  return cloneDeepWith(collection, (value) => {
    if (value && typeof value === 'object') {
      excludeKeys.forEach((key: string | number) => {
        // eslint-disable-next-line no-param-reassign
        delete value[key];
      });
    }
  });
}

async function sendLog(logObj: LogObject) {
  // we should never include any password info in log output
  const logObjWithoutSensitiveInfo = omitDeep(logObj, ['password']);

  const body = JSON.stringify(logObjWithoutSensitiveInfo);
  const useSendBeacon =
    typeof navigator !== 'undefined' && typeof navigator.sendBeacon === 'function';
  if (useSendBeacon) {
    try {
      // use text/plain to avoid a bug on older versions of Chrome: https://bugs.chromium.org/p/chromium/issues/detail?id=724929
      navigator.sendBeacon(endpoint, new Blob([body], { type: 'text/plain' }));
    } catch (beaconError) {
      Sentry.captureException(beaconError);
    }
  } else {
    const fetchOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
      keepalive: true,
    };

    try {
      await fetch(endpoint, fetchOptions);
    } catch (fetchError) {
      // older versions of chrome have a bug with keepalive so as a last ditch effort, try without it
      // https://bugs.chromium.org/p/chromium/issues/detail?id=835821
      // https://bugs.chromium.org/p/chromium/issues/detail?id=835821
      fetchOptions.keepalive = false;
      try {
        await fetch(endpoint, fetchOptions);
      } catch (anotherFetchError) {
        // welp ¯\_(ツ)_/¯
        Sentry.captureException(anotherFetchError);
      }
    }
  }
}

interface StaticsObject {
  [key: string]: any;
}

function normalizeLogObject(
  logObject: LogObject,
  level: LogLevel,
  message?: string
): NormalizedLogObject {
  return {
    ...logObject,
    level,
    message,
  };
}

interface LogFunction {
  (message: string, logObj: LogObject): void;
  (logObj: LogObject): void;
  (message: string): void;
}

let statics: StaticsObject = {};

export function setLoggerStatics(data: StaticsObject) {
  statics = data;
}

function createLogFunction(level: LogLevel): LogFunction {
  return function log(
    messageOrLogObj: string | LogObject,
    maybeLogObj?: LogObject,
    omitStatics?: boolean
  ): void {
    let message;
    let logObj;

    if (typeof messageOrLogObj === 'string') {
      message = messageOrLogObj;
      logObj = maybeLogObj;
    } else {
      logObj = messageOrLogObj;
    }

    const finalLog = omitStatics ? logObj : { ...logObj, ...statics };
    sendLog(normalizeLogObject(finalLog ?? {}, level, message));
  };
}

const Logger = {
  info: createLogFunction('info'),
  warn: createLogFunction('warn'),
  error: createLogFunction('error'),
};

export default Logger;
