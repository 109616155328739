import React, { createContext, useEffect, ReactNode } from 'react';
import { useLazyQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import OverlaySpinner from '@/components/shared/OverlaySpinner';
import {
  GET_ENTERPRISE_ENROLLMENT_COMPONENTS,
  GET_ENTERPRISE_BRANDED_CONTENT,
} from '@/components/request/GQL';
import {
  getEnterpriseEnrollmentComponents,
  getEnterpriseEnrollmentComponents_getEnterpriseEnrollmentComponents as EnterpriseEnrollmentComponents,
  getEnterpriseEnrollmentComponentsVariables,
} from '@/__generated__/getEnterpriseEnrollmentComponents';
import { CLIENT_SIDE_ERROR_TAG, SKIP_AUTH_CONTEXT_KEY } from '@/constants';
import Custom404 from '@/pages/404';
import { sendError } from '@/lib/ClientErrorHandler';
import {
  getEnterpriseBrandedContent,
  getEnterpriseBrandedContentVariables,
  getEnterpriseBrandedContent_getEnterpriseBrandedContent as EnterpriseBrandedContent,
} from '@/__generated__/getEnterpriseBrandedContent';

export interface ClientCustomizationsData {
  customizations: EnterpriseEnrollmentComponents;
  clientContactInformation: EnterpriseBrandedContent['clientContactInformation'];
  cobrandLogo: EnterpriseBrandedContent['cobrandLogo'];
  customizedFooterContent: EnterpriseBrandedContent['customizedFooterContent'];
}

const useContentCustomizations = (group: string) => {
  const [queryCustomizations, { data: customizations, error: customizationsError }] = useLazyQuery<
    getEnterpriseEnrollmentComponents,
    getEnterpriseEnrollmentComponentsVariables
  >(GET_ENTERPRISE_ENROLLMENT_COMPONENTS, {
    context: { [SKIP_AUTH_CONTEXT_KEY]: true },
  });
  const [
    queryEnterpriseBrandedContent,
    { data: enterpriseBrandedContent, error: enterpriseBrandedContentError },
  ] = useLazyQuery<getEnterpriseBrandedContent, getEnterpriseBrandedContentVariables>(
    GET_ENTERPRISE_BRANDED_CONTENT,
    {
      context: { [SKIP_AUTH_CONTEXT_KEY]: true },
    }
  );

  useEffect(
    () => {
      queryCustomizations({
        variables: {
          group,
        },
      });
      queryEnterpriseBrandedContent({
        variables: {
          group,
        },
      });
    },
    [group] // Only re-call effect if client changes
  );

  return {
    data: {
      customizations,
      enterpriseBrandedContent,
    },
    error: {
      customizationsError,
      enterpriseBrandedContentError,
    },
  };
};

export const ClientCustomizationsContext = createContext<ClientCustomizationsData>(
  {} as ClientCustomizationsData
);

interface ClientCustomizationsProps {
  client: string;
  children: ReactNode;
}

export default function ClientCustomizations({ client, children }: ClientCustomizationsProps) {
  const { data, error } = useContentCustomizations(client);
  if (error.customizationsError) {
    sendError(error.customizationsError, {
      tags: [CLIENT_SIDE_ERROR_TAG, 'FatalErrorUserBlocked'],
    });
    Sentry.captureException(error.customizationsError);

    return <Custom404 />;
  }

  if (error.enterpriseBrandedContentError) {
    sendError(error.enterpriseBrandedContentError, {
      tags: [CLIENT_SIDE_ERROR_TAG, 'FatalErrorUserBlocked'],
    });
    Sentry.captureException(error.enterpriseBrandedContentError);

    return <Custom404 />;
  }

  if (!data.customizations || !data.enterpriseBrandedContent) {
    return <OverlaySpinner isOpen wrapped />;
  }

  const contextData: ClientCustomizationsData = {
    customizations: data.customizations.getEnterpriseEnrollmentComponents,
    clientContactInformation:
      data.enterpriseBrandedContent.getEnterpriseBrandedContent.clientContactInformation,
    cobrandLogo: data.enterpriseBrandedContent.getEnterpriseBrandedContent.cobrandLogo,
    customizedFooterContent:
      data.enterpriseBrandedContent.getEnterpriseBrandedContent.customizedFooterContent,
  };

  return (
    <ClientCustomizationsContext.Provider value={contextData}>
      {children}
    </ClientCustomizationsContext.Provider>
  );
}
