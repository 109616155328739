import React, { useCallback, useState, ReactElement } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import { Icon24UtilityHelp } from '@care/react-icons';

interface HelpTooltipProps {
  content: ReactElement;
  onOpen?: Function;
  testId?: string;
}

export default function HelpTooltip({
  content,
  onOpen = () => {},
  testId = 'tooltip-show-button',
}: HelpTooltipProps): ReactElement {
  const [isOpen, setOpen] = useState(false);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);
  const handleOpen = useCallback(() => {
    setOpen(true);
    onOpen();
  }, []);
  return (
    <Box width={24} height={24} mt={0} mr={2} display="flex" onMouseLeave={handleClose}>
      <Tooltip
        sx={{
          zIndex: 1,
          outline: 'none',
          '& + div': {
            width: '230px',
            whiteSpace: 'initial',
          },
        }}
        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleClose}
        open={isOpen}
        disableFocusListener
        disableTouchListener
        disableHoverListener
        title={content}
        placement="top"
        arrow>
        <span
          // data-testid is needed for unit tests
          data-testid={testId}
          onClick={handleOpen}
          onMouseOver={handleOpen}
          onFocus={handleOpen}
          role="button"
          tabIndex={-1}
          aria-hidden="true">
          <Icon24UtilityHelp />
        </span>
      </Tooltip>
    </Box>
  );
}
