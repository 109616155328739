import React, { createContext, useReducer, useContext, ReactNode, useEffect } from 'react';
import { debounce } from 'lodash-es';

import { initialAppState, rootReducer, isStateVersionCompatible } from '../state/index';
import patch from '../state/patch';
import logger, { setLoggerStatics } from '../lib/clientLogger';
import { SafeLocalStorage } from '../utilities/localStorageHelper';
import { LOCAL_STORAGE_STATE_KEY } from '../constants';
import { AppAction, AppDispatch, AppState } from '../types/app';
import { UserState } from '../types/user';
import { initialState as flowInitialState } from '../state/flow/index';
import { initialState as userInitialState } from '../state/user/index';

type AppStateProviderProps = {
  children: ReactNode;
  initialStateOverride?: AppState;
  czenJSessionId?: string;
  referrerCookie?: string | null;
  initialUserData?: UserState;
};

function getStateFromStorageOrDefault(defaultState: AppState) {
  const stateFromStorageJson = SafeLocalStorage.getInstance().getItem(LOCAL_STORAGE_STATE_KEY);
  if (stateFromStorageJson) {
    const stateFromStorage = JSON.parse(stateFromStorageJson) as AppState;
    const patchedState = patch(stateFromStorage);

    if (isStateVersionCompatible(patchedState)) {
      return patchedState;
    }
    logger.warn({ event: 'invalidStateFromLocalStorage', state: stateFromStorageJson });
  }
  return defaultState;
}

function persistState(state: AppState) {
  SafeLocalStorage.getInstance().setItem(LOCAL_STORAGE_STATE_KEY, JSON.stringify(state));
}

const debouncePersistState = debounce(persistState, 500, {
  leading: false,
  trailing: true,
  maxWait: 1000,
});

function reducerWrapper(state: AppState, action: AppAction) {
  const nextState = rootReducer(state, action);
  debouncePersistState(nextState);
  return nextState;
}

function useFlowDetection(dispatch: AppDispatch) {
  const newFlowName = 'Enrollment';
  setLoggerStatics({ flow: newFlowName });

  useEffect(() => {
    dispatch({ type: 'setFlowName', flowName: newFlowName });
  }, [newFlowName]);
}

const AppStateContext = createContext<AppState>(initialAppState);
const AppDispatchContext = createContext<AppDispatch>(() =>
  // eslint-disable-next-line no-console
  console.warn(
    'Calling default dispatch is a no-op! You are probably consuming app dispatch in a component above AppStateProvider'
  )
);

export const AppStateProvider = ({
  children,
  initialStateOverride,
  initialUserData,
  czenJSessionId,
  referrerCookie,
}: AppStateProviderProps) => {
  const hydratedInitialAppState: AppState = {
    ...initialAppState,
    flow: {
      ...flowInitialState,
      czenJSessionId,
      referrerCookie,
    },
    user: {
      ...userInitialState,
      ...initialUserData,
    },
  };
  const [state, dispatch] = useReducer(
    reducerWrapper,
    initialStateOverride ?? hydratedInitialAppState,
    getStateFromStorageOrDefault
  );
  useFlowDetection(dispatch);

  return (
    <AppDispatchContext.Provider value={dispatch}>
      <AppStateContext.Provider value={state}>{children}</AppStateContext.Provider>
    </AppDispatchContext.Provider>
  );
};

AppStateProvider.defaultProps = {
  initialStateOverride: undefined,
  initialUserData: {},
  czenJSessionId: undefined,
  referrerCookie: undefined,
};

export const clearState = () => {
  SafeLocalStorage.getInstance().removeItem(LOCAL_STORAGE_STATE_KEY);
};

export const useAppState = () => useContext(AppStateContext);
export const useAppDispatch = () => useContext(AppDispatchContext);

export const useFlowState = () => useContext(AppStateContext).flow;
export const useEnterpriseState = () => useContext(AppStateContext).enterprise;
export const useUserState = () => useContext(AppStateContext).user;
