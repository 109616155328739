import React from 'react';
import { Grid, Container, Typography } from '@mui/material';

import useWindowSize from '@/components/hooks/useWindowSize';
import { TEXTS } from '@/constants';

const {
  STATIC: {
    PAGE_NOT_FOUND: { HEADER, BODY_1, BODY_2 },
  },
} = TEXTS;

export default function Custom404() {
  const windowSize = useWindowSize();

  return (
    <Container
      id="custom-404"
      sx={{
        py: 3,
        overflowX: 'hidden',
        flex: '1 0 auto',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
      }}
      style={{
        minHeight: windowSize?.height,
      }}>
      <Grid
        container
        sx={{
          py: 3,
          overflowX: 'hidden',
          flex: '1 0 auto',
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
        }}>
        <Grid item xs={12}>
          <Typography variant="h2">{HEADER}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">{BODY_1}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">{BODY_2}</Typography>
        </Grid>
      </Grid>
    </Container>
  );
}
