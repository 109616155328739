import { ApolloProvider } from '@apollo/client';
import React, { useEffect } from 'react';
import { AppContext, AppProps, NextWebVitalsMetric } from 'next/app';
import Router from 'next/router';
import getConfig from 'next/config';
import Head from 'next/head';
import 'resize-observer-polyfill';

import { Divider, CssBaseline, Container, ThemeProvider, Box, SxProps, Theme } from '@mui/material';
import { theme as careTheme } from '@care/material-ui-theme';
import AccessiBe from '@care/accessibe';
import DayJSUtils from '@date-io/dayjs';
import Cookies from 'universal-cookie';
import * as Sentry from '@sentry/react';
import { isMobile as deviceDetectorIsMobile } from 'react-device-detect';
import { useApollo } from '@/lib/ApolloClient';
import AuthService from '@/lib/AuthService';
import { AppStateProvider } from '@/components/AppState';
import { sendError } from '@/lib/ClientErrorHandler';
import logger from '@/lib/clientLogger';
import PageTransition from '@/components/PageTransition';
import ClientHeader from '@/components/shared/ClientHeader';
import ClientFooter from '@/components/shared/ClientFooter';
import ClientCustomizations from '@/components/ClientCustomizations';
import useGoogleSignInData from '@/components/hooks/useGoogleSignInData';
import { UserState } from '@/types/user';
import AnalyticsHelper from '@/utilities/analyticsHelper';
import { urlHelper, validateRoute } from '@/utilities/urlHelper';
import {
  CZEN_JSESSIONID_COOKIE_KEY,
  CZEN_VISITOR_COOKIE_KEY,
  ENTERPRISE_ROUTES,
  FEATURE_FLAGS,
  CZEN_SESSION_COOKIE_KEY,
} from '@/constants';
import '../../global.css';
import useSSOData from '@/components/hooks/useSSOData';
import PageAccessGuard from '@/components/PageAccessGuard';
import { FeatureFlags, FeatureFlagsProvider } from '@/context/FeatureFlagsContext';
import useDirectEnrollmentData from '@/components/hooks/useDirectEnrollmentData';
import { SiftScienceContextProvider } from '@/context/SiftScienceContext';
import { GtmHelper } from '@care/google-tag-manager';
import { GtmService } from '@/lib/GtmService';
import OverlaySpinner from '../components/shared/OverlaySpinner';
import Custom404 from './404';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { SxClassProps } from '@/types/global';

const {
  publicRuntimeConfig: { CZEN_GENERAL, SENTRY_ENV },
} = getConfig();

// Overriding https://github.com/dmtrKovalenko/date-io/blob/7b6f74e9a75dc6468ab869e77b562390ed57efb7/packages/dayjs/src/dayjs-utils.ts#L104
// making dayjs parse dates in strict mode which prevents "correction" of dates like 43/36/2000 -> 08/05/2003
DayJSUtils.prototype.parse = function parseStrict(value, format): any {
  if (value === '') {
    return null;
  }
  return this.dayjs(value, format, this.locale, true);
};

Router.events.on('routeChangeComplete', () => {
  window.scrollTo(0, 0);
});

if (typeof window !== 'undefined') {
  // Next.js will automatically catch JS errors that happen during the rendering phase via _errors.tsx
  // for errors that may happen outside of rendering (i.e. part of a click handler), we need to rely on these events
  window.addEventListener('error', sendError, false);
  window.addEventListener('unhandledrejection', sendError, false);
}

const classes: SxClassProps = {
  root: {
    paddingTop: { xs: 3, md: 6 },
    paddingBottom: 3,
    overflowX: 'hidden',
    flex: '1 0 auto',
  },
  content: {
    margin: 'auto',
    maxWidth: '410px',
  },
  wrapper: (theme) => ({
    // accounting for header height + 1px border to avoid unnecessary scrolling
    minHeight: `calc(100vh - (${theme.spacing(7)} + 1px))`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      // accounting for header height + 1px border to avoid unnecessary scrolling
      minHeight: `calc(100vh - (${theme.spacing(8)} + 1px))`,
    },
    // only targets IE10 and IE11
    '@media all and (-ms-high-contrast:none)': {
      height: `calc(100vh - (${theme.spacing(8)} + 1px))`,
    },
  }),
  footerPush: (theme) => ({
    [theme.breakpoints.down('md')]: {
      minHeight: `calc(100vh - (${theme.spacing(7)} + 1px))`,
    },
  }),
};

type ErrorData = {
  errorMsg: string;
  path?: string;
  payload?: { [key: string]: any };
  serverError?: { [key: string]: any };
  [key: string]: any;
};

const initialProps = {
  onError(type: string, errorData: ErrorData) {
    const { errorMsg, path, payload, serverError } = errorData;

    logger.error(errorMsg, {
      tags: [type],
      path,
      payload,
      serverError,
    });
  },
};
const APP_STORE_ID = 'care.ios.app.appstore.id';
const ANDROID_APP_STORE_ID = 'care.android.app.appstore.id';
const TOUCH_ICONS_BASE_URL = `${CZEN_GENERAL}/img/mw/touchIcons`;
const touchIcons = [
  // For iPad with high-resolution Retina display running iOS 7:
  {
    sizes: '152x152',
    href: `${TOUCH_ICONS_BASE_URL}/apple-touch-icon-152x152-precomposed.png`,
  },
  // For iPad with high-resolution Retina display running iOS 6:
  {
    sizes: '144x144',
    href: `${TOUCH_ICONS_BASE_URL}/apple-touch-icon-144x144-precomposed.png`,
  },
  // For iPhone with high-resolution Retina display running iOS 7:
  {
    sizes: '120x120',
    href: `${TOUCH_ICONS_BASE_URL}/apple-touch-icon-120x120-precomposed.png`,
  },
  // For iPhone with high-resolution Retina display running iOS 6:
  {
    sizes: '114x114',
    href: `${TOUCH_ICONS_BASE_URL}/apple-touch-icon-114x114-precomposed.png`,
  },
  // For the iPad mini and the first- and second-generation iPad on iOS 7:
  {
    sizes: '76x76',
    href: `${TOUCH_ICONS_BASE_URL}/apple-touch-icon-76x76-precomposed.png`,
  },
  // For the iPad mini and the first- and second-generation iPad on iOS 6:
  {
    sizes: '72x72',
    href: `${TOUCH_ICONS_BASE_URL}/apple-touch-icon-72x72-precomposed.png`,
  },
  // For non-Retina iPhone, iPod Touch, and Android 2.1+ devices:
  {
    href: `${TOUCH_ICONS_BASE_URL}/apple-touch-icon-precomposed.png`,
  },
];

function getMeta(favIconUrl: string) {
  return (
    <>
      <title>Access Your Employee Care Benefits | Enroll Today</title>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta
        name="description"
        content="Enroll or log in to your employer-sponsored benefits through Care.com and find Backup Care for your child, pet, or senior loved ones."
      />
      <meta
        name="keywords"
        content="Care.com, care, care for business, benefits care.com, care.com benefits, care.com employee benefits, care benefits, care com employee benefits, care.com for employees, employer benefits, employer childcare benefits, employer sponsored child care, benefit, benefits, backup care, back up care, child back up care, child backup care, care.com backup care, care.com reimbursement, back up care care.com, back up care services, care backup care, care com back up care, back-up care, back-up child care, daycare, day care, child care, childcare, babysitter, sitter, nanny, home care, adult care, elder care, senior care, pet care, pet back up care, tutoring, on demand tutoring, lifecare, college coaching, care specialists, care specialist, enroll, join, book care, get care"
      />
      <meta name="apple-itunes-app" content={`app-id=${APP_STORE_ID}`} />
      <meta name="google-play-app" content={`app-id=${ANDROID_APP_STORE_ID}`} />
      <link rel="shortcut icon" href={favIconUrl} />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      {touchIcons.map((icon) => (
        <link
          key={icon.href}
          rel="apple-touch-icon-precomposed"
          href={icon.href}
          sizes={icon.sizes}
        />
      ))}
    </>
  );
}

type EnrollmentAppProps = AppProps & {
  enrollmentSessionId: string;
  czenVisitorId?: string;
  czenJSessionId?: string;
  cscSessionId?: string;
  referrerCookie?: string;
  isAccessibeEnabled: boolean;
  err: Error;
  featureFlags: FeatureFlags;
  nonce: string;
};

export default function App({
  Component,
  pageProps,
  router,
  err,
  enrollmentSessionId,
  czenVisitorId,
  czenJSessionId,
  cscSessionId,
  referrerCookie,
  isAccessibeEnabled,
  featureFlags,
  nonce,
}: EnrollmentAppProps) {
  const favIconUrl =
    urlHelper(featureFlags[FEATURE_FLAGS.TTP_FAVICON_URL?.key]?.value) ||
    `${CZEN_GENERAL}/img/favicon_new.ico`;

  const cache = createCache({ key: 'emotion-cache', nonce, prepend: true });

  // TODO: Discuss how we want to handle SSR moving forward
  if (typeof window === 'undefined') {
    return (
      <>
        <Head>
          {getMeta(favIconUrl)}
          {isAccessibeEnabled && <AccessiBe />}
        </Head>
        <CacheProvider value={cache}>
          <ThemeProvider theme={careTheme}>
            <CssBaseline />
          </ThemeProvider>
        </CacheProvider>
      </>
    );
  }
  // authCallback does its thing and redirects to a proper page
  if (router.route === '/authCallback') {
    return (
      <CacheProvider value={cache}>
        <ThemeProvider theme={careTheme}>
          <Component {...pageProps} err={err} />
        </ThemeProvider>
      </CacheProvider>
    );
  }
  const authService = AuthService();
  const apolloClient = useApollo(initialProps, authService);
  const client =
    (router.query.groupName as string) ||
    useDirectEnrollmentData().fetch().groupShortName ||
    'directEnrollment';
  const ssoPartnerInfoId = router.query.ssoPartnerInfoId as string;
  const userStateWithGoogleSignInData = useGoogleSignInData();
  const ssoDirectRegistrationEval =
    featureFlags[FEATURE_FLAGS.ENT_SSO_PLUS_DIRECT_REGISTRATION_FLAG.key]?.value || false;
  const isDirectEnrollmentRoute = router.route.match(/member-enrollment/);
  const [userStateWithSSOData, ssoError] = useSSOData(
    apolloClient,
    client,
    ssoPartnerInfoId,
    ssoDirectRegistrationEval,
    Boolean(isDirectEnrollmentRoute)
  );
  const initialUserData: Partial<UserState> = { ...userStateWithGoogleSignInData };

  const safetySiftOldConsoleEnabled =
    featureFlags[FEATURE_FLAGS.SAFETY_SIFT_OLD_CONSOLE_ENABLED.key]?.value || false;
  const safetySiftUserJourneyEnabled =
    featureFlags[FEATURE_FLAGS.SAFETY_SIFT_USER_JOURNEY_ENABLED.key]?.value || false;

  useEffect(() => {
    if (router.pathname !== '/authCallback') {
      // add this line to exclude /authCallback if needed
      GtmHelper.pushFromSessionStorageUsingGtmService(GtmService);
    }
  }, [router.pathname]);

  useEffect(() => {
    Sentry.setUser({
      // TODO: use the universal device ID when available
      id: enrollmentSessionId,
      // other user identifiers that might be useful for debugging purposes
      enrollmentSessionId,
      czenVisitorId,
    });
  }, [enrollmentSessionId]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    if (client) {
      AnalyticsHelper.init(deviceDetectorIsMobile, client, SENTRY_ENV, cscSessionId);
    }
  }, [deviceDetectorIsMobile, client, SENTRY_ENV]);

  // unsupported routes will show 404 error
  if (!validateRoute(router.route, ENTERPRISE_ROUTES) && !isDirectEnrollmentRoute) {
    return (
      <CacheProvider value={cache}>
        <ThemeProvider theme={careTheme}>
          <Component {...pageProps} err={err} />
        </ThemeProvider>
      </CacheProvider>
    );
  }

  if (ssoPartnerInfoId && !isDirectEnrollmentRoute) {
    if (!userStateWithSSOData && !ssoError)
      return (
        <CacheProvider value={cache}>
          <ThemeProvider theme={careTheme}>
            <OverlaySpinner isOpen wrapped />
          </ThemeProvider>
        </CacheProvider>
      );
    if (ssoError)
      return (
        <CacheProvider value={cache}>
          <ThemeProvider theme={careTheme}>
            <Custom404 />
          </ThemeProvider>
        </CacheProvider>
      );
    if (userStateWithSSOData) Object.assign(initialUserData, userStateWithSSOData);
  }

  const lifecareTransition = (
    <AppStateProvider
      czenJSessionId={czenJSessionId}
      referrerCookie={referrerCookie}
      initialUserData={initialUserData}>
      <ClientCustomizations client={client}>
        <PageAccessGuard>
          <ClientHeader />
          <Divider />
          <Box component="div" sx={classes.wrapper}>
            <Box component="div" sx={classes.footerPush}>
              <Container sx={classes.root}>
                {/* manually passing any errors here for _errors.tsx because https://github.com/vercel/next.js/issues/8592 */}
                <PageTransition>
                  <Box
                    component="section"
                    sx={{
                      margin: 'auto',
                      maxWidth: '410px',
                    }}
                    key={router.route}>
                    <Component {...pageProps} err={err} />
                  </Box>
                </PageTransition>
              </Container>
            </Box>
            <ClientFooter />
          </Box>
        </PageAccessGuard>
      </ClientCustomizations>
    </AppStateProvider>
  );

  const directToEnrollment = <Component {...pageProps} err={err} />;

  const renderFlow = () =>
    router.route.match(/member-enrollment/) ? directToEnrollment : lifecareTransition;

  return (
    <>
      <Head>
        {getMeta(favIconUrl)}
        {isAccessibeEnabled && <AccessiBe />}
      </Head>
      <CacheProvider value={cache}>
        <ThemeProvider theme={careTheme}>
          <CssBaseline />
          <ApolloProvider client={apolloClient}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <SiftScienceContextProvider
                czenJSessionId={czenJSessionId}
                oldConsoleEnabled={safetySiftOldConsoleEnabled}
                newConsoleEnabled={safetySiftUserJourneyEnabled}>
                <FeatureFlagsProvider flags={featureFlags}>{renderFlow()}</FeatureFlagsProvider>
              </SiftScienceContextProvider>
            </LocalizationProvider>
          </ApolloProvider>
        </ThemeProvider>
      </CacheProvider>
    </>
  );
}

/**
 * Invoked during SSR to build the map of data we need to provide to the client.  This function also serves to disable
 * static page generation in favor of SSR for *all* pages, which is currently needed in order to inject
 * the proper env-specific config at runtime rather than at build time.
 * @param appContext
 */
export async function getInitialProps(appContext: AppContext) {
  const {
    ctx: { req, query },
  } = appContext;
  let enrollmentSessionId;
  let czenVisitorId;
  let czenJSessionId: string | undefined;
  let cscSessionId;
  let referrerCookie;
  let isAccessibeEnabled = FEATURE_FLAGS.ACCESSIBE_ENABLED_LD_FLAG.defaultValue;
  let infoLog;
  const featureFlags: FeatureFlags = {};
  const { nonce } = req?.careContext || {};

  if (req) {
    // parse cookies from the request with a no-op decoder so we don't make any assumptions about how
    // the `rc` cookie is encoded by czen
    // TODO: Retrieve all cookies the flows care about, like n_tc and n_vis
    const cookies = new Cookies(req.headers.cookie, {
      decode: (c) => c,
    });
    const referrerCookieEncoded = cookies.get('rc');

    // TODO: remove the call to `decodeURIComponent` below after CAREUS-96857 has been addressed and we no longer
    // need to decode the cookie ourselves
    referrerCookie = referrerCookieEncoded ? decodeURIComponent(referrerCookieEncoded) : null;

    ({ enrollmentSessionId } = req);
    czenVisitorId = cookies.get(CZEN_VISITOR_COOKIE_KEY) ?? undefined;
    czenJSessionId = cookies.get(CZEN_JSESSIONID_COOKIE_KEY) ?? undefined;
    cscSessionId = cookies.get(CZEN_SESSION_COOKIE_KEY) ?? undefined;

    // Figuring out if a current group is enabled in the LaunchDarkly.
    const { ldClient, ldUser, careDeviceId } = req.careContext || {};
    const { groupName, utm_campaign: utmCampaign } = query || {};

    if (ldClient && ldUser) {
      const webPlatform = req.headers['user-agent']
        ? getWebPlatform(req.headers['user-agent'])
        : '';

      const flagPromise = Object.values(FEATURE_FLAGS).map(
        async ({ key, defaultValue, requireGroupName }) => {
          if (requireGroupName && groupName) {
            featureFlags[key] = await ldClient.variationDetail(
              key,
              {
                ...ldUser,
                custom: {
                  ...ldUser.custom,
                  groupShortName: groupName,
                  webPlatform,
                },
              },
              defaultValue
            );
          }
          if (!requireGroupName) {
            featureFlags[key] = await ldClient.variationDetail(
              key,
              {
                ...ldUser,
                custom: {
                  ...ldUser.custom,
                  campaignId: utmCampaign as string,
                  careDeviceID: careDeviceId as string,
                  webPlatform,
                },
              },
              defaultValue
            );
          }
        }
      );
      await Promise.all(flagPromise);
      isAccessibeEnabled = featureFlags[FEATURE_FLAGS.ACCESSIBE_ENABLED_LD_FLAG.key]?.value;
    }
  }

  return {
    enrollmentSessionId,
    czenVisitorId,
    czenJSessionId,
    cscSessionId,
    referrerCookie,
    isAccessibeEnabled,
    featureFlags,
    infoLog,
    nonce,
  };
}

App.getInitialProps = getInitialProps;

const getWebPlatform = (userAgent: string) => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)
    ? 'mobile_web'
    : 'desktop';
};

/**
 * Enables Next.js' performance reporting:
 *
 * https://nextjs.org/docs/advanced-features/measuring-performance
 *
 * @param metric
 */
export function reportWebVitals(metric: NextWebVitalsMetric) {
  logger.info({
    tags: ['performance'],
    metric,
  });
}
