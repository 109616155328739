import fetch from 'cross-fetch';
import { useMemo } from 'react';
import { SourceLocation } from 'graphql';
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  ServerError,
  ServerParseError,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { setContext } from '@apollo/client/link/context';
import getConfig from 'next/config';
import { SKIP_AUTH_CONTEXT_KEY } from '@/constants';
import { Authenticable } from './AuthService';
import shouldRetry from './apolloRetryLink';

const { publicRuntimeConfig } = getConfig();

let apolloClient: ApolloClient<any>;
let auth: Authenticable;

function createApolloClient(props: any) {
  const httpLink = new HttpLink({
    uri: publicRuntimeConfig.GRAPHQL_URL,
    fetch,
  });

  const authLink = setContext((operation, prevContext) => {
    const { headers } = prevContext;
    if (auth && prevContext[SKIP_AUTH_CONTEXT_KEY] !== true) {
      const authData = auth.getStore() || {};
      if (authData && authData.access_token) {
        return {
          headers: {
            ...headers,
            authorization: authData.access_token ? `Bearer ${authData.access_token}` : '',
          },
        };
      }
    }
    return { headers };
  });

  const cache = new InMemoryCache();

  const getLocationsString = (locations: readonly SourceLocation[] | undefined) => {
    let locationString = '';

    if (locations) {
      locationString = locations
        .map(({ column, line }) => `Line ${line} - Column ${column}`)
        .join(' | ');
    }

    return locationString;
  };

  const getPathString = (pathArray: readonly (string | number)[] | undefined) => {
    let pathString = '';
    if (pathArray) {
      pathString = pathArray.map((path) => `${path}`).join(' | ');
    }
    return pathString;
  };

  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    const elapsed = Math.floor(performance.now() - operation.getContext().start);

    const gqlPayload = {
      operationName: operation.operationName,
      elapsed,
    };

    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path, extensions = {} }) => {
        const pathString = getPathString(path);
        const locationString = getLocationsString(locations);
        const errorMsg = `[GraphQL error]: Message: ${message}, ${
          locationString ? `Location: ${locationString},` : ''
        } ${pathString ? `Path: ${pathString}` : ''}`;
        const errorData = {
          errorMsg,
          path: pathString,
          payload: gqlPayload,
          serverError: {
            ...extensions,
          },
        };
        return props.onError('GraphQL error', errorData);
      });
    }

    if (networkError) {
      const { name, message, stack, cause } = networkError;
      const { result, statusCode } = networkError as Partial<ServerError>;
      const { bodyText } = networkError as Partial<ServerParseError>;

      const errorData = {
        errorMsg: `[Network error ${operation.operationName}]: ${message}`,
        payload: gqlPayload,
        serverError: {
          cause,
          name,
          message,
          stack,
          bodyText,
          statusCode,
          networkError: result,
        },
      };
      props.onError('Network error', errorData);

      if (!statusCode) {
        // logout only if unauthorized
        auth.logout();
      }
    }
  });

  const retryLink = new RetryLink({
    attempts: shouldRetry,
  });

  const timeStartLink = new ApolloLink((operation, forward) => {
    operation.setContext({ start: performance.now() });
    return forward(operation);
  });

  const link = ApolloLink.from([timeStartLink, authLink, errorLink, retryLink, httpLink]);

  return new ApolloClient({
    name: publicRuntimeConfig.APP_NAME,
    version: publicRuntimeConfig.APP_VERSION,
    ssrMode: typeof window === 'undefined',
    link,
    cache,
  });
}

export function initializeApollo(initialState: any) {
  const apolloClientState = apolloClient ?? createApolloClient(initialState);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = apolloClientState.extract();
    // Restore the cache using the data passed from getStaticProps/getServerSideProps
    // combined with the existing cached data
    apolloClientState.cache.restore({ ...existingCache, ...initialState });
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return apolloClientState;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = apolloClientState;

  return apolloClientState;
}

export function useApollo(initialState: any, authService: Authenticable) {
  auth = authService;
  const store = useMemo(() => initializeApollo(initialState), [initialState]);
  return store;
}
