/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["draft"] }] */

import produce from 'immer';

import { UserState, UserAction, ActionTypes } from '@/types/user';
import { AppDispatch } from '@/types/app';
/* eslint-disable import/prefer-default-export */
export const initialState: UserState = {
  firstName: '',
  lastName: '',
  zipCode: '',
};

export const reducer = produce((draft: UserState, action: UserAction) => {
  switch (action.type) {
    case ActionTypes.UPDATE_USER:
      return { ...draft, ...action.data };
    default:
      return draft;
  }
});

export const getUserActions = (dispatch: AppDispatch) => {
  return {
    updateUser(data: Partial<UserState>) {
      dispatch({ type: ActionTypes.UPDATE_USER, data });
    },
  };
};
