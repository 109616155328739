import { createElement } from 'react';
import { sanitize, Config } from 'dompurify';

const defaultOptions = {
  ALLOWED_TAGS: ['b', 'i', 'em', 'strong', 'a', 'p', 'span', 'div'],
  ALLOWED_ATTR: ['href'],
};

const sanitizeHTML = (dirty: string, options?: Config) => ({
  __html: sanitize(dirty, { ...defaultOptions, ...options }) as string,
});

/* eslint-disable react/require-default-props */
interface HTMLSanitizerProps {
  html: string;
  component?: 'div' | 'span';
  options?: Config;
}
export default function HTMLSanitizer({ html, options, component = 'span' }: HTMLSanitizerProps) {
  return createElement(component, { dangerouslySetInnerHTML: sanitizeHTML(html, options) });
}
