import getConfig from 'next/config';

const {
  publicRuntimeConfig: { CZEN_GENERAL },
} = getConfig();

export const urlHelper = (url: string | undefined) => {
  return (url && (url.includes(CZEN_GENERAL) ? url : `${CZEN_GENERAL}${url}`)) || undefined;
};

export const validateRoute = (route: string, validRoutes: Readonly<{ (key: string): string }>) => {
  return route.match(`^/.+(${Object.values(validRoutes).join('|')})$`);
};
