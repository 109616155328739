import React, { ReactNode } from 'react';
import { useRouter } from 'next/router';
import dayjs from 'dayjs';
import { Schema } from 'yup';
import { useEnterpriseState, useFlowState } from '@/components/AppState';
import { ENTERPRISE_ROUTES } from '@/constants';
import Custom404 from '@/pages/404';
import { EligibleFormValues } from '@/types/eligbility';
import { getValidationSchema as getConfirmEligibilitySchema } from '@/pages/[groupName]/confirm-eligibility';
import { EnterpriseState } from '@/types/enterprise';
import useClientCustomizations from '@/components/hooks/useClientCustomizations';

type PageAccessGuardProps = {
  children: ReactNode;
};

const {
  CONFIRM_ELIGIBILITY,
  ACCOUNT_CREATION,
  CONNECT_ACCOUNT,
  VERTICAL_PREFERENCES,
  LIFE_CARE_ACCOUNT_VERIFICATION,
  LIFE_CARE_LOGIN,
  LIFE_CARE_FORGOT_PASSWORD,
} = ENTERPRISE_ROUTES;

type StateCopy = Omit<EnterpriseState, 'dateOfBirth'> & { dateOfBirth: dayjs.Dayjs };

interface CheckingItems {
  eligibleValuesValidationSchema: Schema<EligibleFormValues>;
  stateCopy: StateCopy;
  memberId?: string;
}

function checkEligibilityPageValues(state: StateCopy, schema: Schema<EligibleFormValues>) {
  try {
    return Boolean(schema.validateSync(state));
  } catch (e) {
    return false;
  }
}

function checkMemberId(memberId: string | undefined): boolean {
  return Boolean(memberId);
}

const checksForPageAccessMap = {
  [`/[groupName]${CONFIRM_ELIGIBILITY}`]: () => true,
  [`/[groupName]${ACCOUNT_CREATION}`]: ({
    eligibleValuesValidationSchema,
    stateCopy,
  }: CheckingItems) => {
    return checkEligibilityPageValues(stateCopy, eligibleValuesValidationSchema);
  },
  [`/[groupName]${CONNECT_ACCOUNT}`]: () => true,
  [`/[groupName]${VERTICAL_PREFERENCES}`]: ({ memberId }: CheckingItems) => {
    return checkMemberId(memberId);
  },
  [`/[groupName]${LIFE_CARE_ACCOUNT_VERIFICATION}`]: () => true,
  [`/[groupName]${LIFE_CARE_LOGIN}`]: () => true,
  [`/[groupName]${LIFE_CARE_LOGIN}?otp=true`]: () => true,
  [`/[groupName]${LIFE_CARE_FORGOT_PASSWORD}`]: () => true,
};

export default function PageAccessGuard({ children }: PageAccessGuardProps) {
  const { route } = useRouter();
  const enterpriseState = useEnterpriseState();
  const { getField } = useClientCustomizations();
  const emailComponent = getField('email');
  const employeeIdComponent = getField('employeeId');
  const rolesComponent = getField('roles');
  const { memberId } = useFlowState();
  const stateCopy = {
    ...enterpriseState,
    ...{ dateOfBirth: dayjs(enterpriseState.dateOfBirth) },
  };
  const eligibleValuesValidationSchema = getConfirmEligibilitySchema({
    emailComponent,
    employeeIdComponent,
    rolesComponent,
  });

  return checksForPageAccessMap[route]({
    eligibleValuesValidationSchema,
    stateCopy,
    memberId,
  }) ? (
    <>{children}</>
  ) : (
    <Custom404 />
  );
}
