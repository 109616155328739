/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["draft"] }] */

import produce from 'immer';

import { EnterpriseAction, EnterpriseState, ActionTypes } from '@/types/enterprise';
import { AppDispatch } from '@/types/app';

export const initialState: EnterpriseState = {
  dateOfBirth: '',
  email: '',
  employeeId: '',
  firstName: '',
  lastName: '',
  location: {
    zipcode: '',
    city: '',
    state: '',
  },
  address: '',
  role: '',
};

export const reducer = produce((draft: EnterpriseState, action: EnterpriseAction) => {
  switch (action.type) {
    case ActionTypes.UPDATE_ENTERPRISE_STATE:
      return { ...draft, ...action.data };
    default:
      return draft;
  }
});

export const getEnterpriseActions = (dispatch: AppDispatch) => {
  return {
    updateEnterpriseState(data: Partial<EnterpriseState>) {
      dispatch({ type: ActionTypes.UPDATE_ENTERPRISE_STATE, data });
    },
  };
};
