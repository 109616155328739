import React, { useEffect, ChangeEvent } from 'react';
import { InputAdornment } from '@mui/material';
import { InlineTextField } from '@care/react-component-lib';
import { Icon24InfoLocation } from '@care/react-icons';
import useZipLocation from '../features/zipLocation/useZipLocation';

export interface Location {
  zipcode: string;
  city: string;
  state: string;
}
interface Props {
  location: Location;
  onChange: (location: Location) => void;
  onError: (error: boolean) => void;
  disabled?: boolean;
}

const ZipInput = ({ location, onChange, onError, disabled = false }: Props) => {
  const { zipcode, city, state, helperText, error, loading, validateZipCode, setZipcode } =
    useZipLocation(location.zipcode);

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newZipcode = e.target.value;
    setZipcode(newZipcode);
  };

  const onInputBlur = () => {
    validateZipCode(true);
  };

  useEffect(() => {
    validateZipCode(false);
  }, [zipcode]);

  useEffect(() => {
    onError(error || loading);
  }, [error, loading]);

  useEffect(() => {
    onChange({ zipcode, city, state });
  }, [zipcode, city, state]);

  return (
    <InlineTextField
      id="zipCode"
      name="zipCode"
      label="ZIP code"
      helperText={zipcode && helperText}
      error={Boolean(error && zipcode)}
      value={zipcode}
      onChange={onInputChange}
      onBlur={onInputBlur}
      disabled={disabled}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Icon24InfoLocation />
          </InputAdornment>
        ),
      }}
      sx={{
        paddingLeft: 0,
        paddingRight: 0,
        // removes number arrows from Chrome, Safari, Edge, Opera
        '& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
          appearance: 'none',
          margin: 0,
        },
        // removes number arrows from Firefox
        '& input[type=number]': {
          appearance: 'textfield',
        },
      }}
    />
  );
};

export default ZipInput;
