import React, { useState, MouseEvent } from 'react';
import getConfig from 'next/config';
import {
  AppBar,
  Toolbar,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Button,
  Typography,
  Menu,
  Box,
  Link,
} from '@mui/material';
import { Divider } from '@care/react-component-lib';
import { Icon24UtilityHelp, Icon24InfoMobilePhone, Icon24InfoInbox } from '@care/react-icons';
import { useEventLogger } from '@/utilities/analyticsHelper';
import { EventNames, TEXTS } from '@/constants';

const {
  publicRuntimeConfig: { HELPCENTER_URL },
} = getConfig();

const {
  STATIC: {
    HEADER: { NEED_HELP, HELP_CENTER, PAGE_VARIANTS },
  },
} = TEXTS;

export interface AppHeaderProps {
  cobrandLogo: {
    clickable: boolean;
    imageURL: string;
    altText: string | null;
    redirectUrl: string;
  };
  contactUs: {
    phoneNumber?: string | null;
    email?: string | null;
  };
}

export default function AppHeader({
  cobrandLogo: { imageURL, altText, clickable, redirectUrl },
  contactUs: { phoneNumber, email },
}: AppHeaderProps) {
  const logger = useEventLogger({ page_variant: PAGE_VARIANTS.Default });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleAmplitudeEvent = (name: string) => {
    logger({
      name,
    });
  };

  const handleMenuClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    handleAmplitudeEvent(EventNames.NEED_HELP_CLICKED);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const image = (
    // eslint-disable-next-line jsx-a11y/alt-text
    <Box
      component="img"
      src={imageURL}
      {...(altText && { alt: altText })}
      sx={{
        width: { xs: '200px', sm: '250px', md: '350px' },
      }}
      data-testid="header-logo"
    />
  );

  return (
    <AppBar color="transparent" elevation={0} position="static">
      <Toolbar>
        <Box
          component="div"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'center',
          }}>
          {clickable ? (
            <a
              href={redirectUrl}
              onClick={() => {
                handleAmplitudeEvent(EventNames.ENTERPRISE_LOGO_CLICKED);
              }}>
              {image}
            </a>
          ) : (
            image
          )}
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleMenuClick}
            sx={{
              width: 'auto',
              marginRight: -2,
              px: { xs: 1, sm: 2 },
              py: 1,
            }}
            startIcon={<Icon24UtilityHelp />}
            data-testid="need-help-button">
            <Typography
              variant="body2"
              sx={{
                display: { xs: 'none', md: null },
              }}>
              {NEED_HELP}
            </Typography>
          </Button>
          <Menu
            id="simple-menu"
            data-testid="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}>
            <MenuItem key="help">
              <ListItemText>
                <Typography variant="body2">
                  <Link
                    sx={{
                      textDecoration: 'none',
                      color: 'inherit',
                    }}
                    href={HELPCENTER_URL}>
                    {HELP_CENTER}
                  </Link>
                </Typography>
              </ListItemText>
            </MenuItem>
            {(email || phoneNumber) && (
              <Divider
                sx={{
                  mx: 0,
                  my: 2,
                  px: 2,
                  py: 0,
                }}
              />
            )}
            {phoneNumber && (
              <MenuItem key="phone">
                <ListItemIcon>
                  <Icon24InfoMobilePhone />
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="body2">
                    <Link
                      sx={{
                        textDecoration: 'none',
                        color: 'inherit',
                      }}
                      href={`tel:${phoneNumber}`}>
                      {phoneNumber}
                    </Link>
                  </Typography>
                </ListItemText>
              </MenuItem>
            )}
            {email && (
              <MenuItem key="mail">
                <ListItemIcon>
                  <Icon24InfoInbox />
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="body2">
                    <Link
                      sx={{
                        textDecoration: 'none',
                        color: 'inherit',
                      }}
                      href={`mailto:${email}`}>
                      {email}
                    </Link>
                  </Typography>
                </ListItemText>
              </MenuItem>
            )}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
