import { GtmServiceBase } from '@care/google-tag-manager';

import logger from './clientLogger';

class GtmServiceExtended extends GtmServiceBase {}

export const GtmService = new GtmServiceExtended({
  logger,
});

export { GtmEvent } from '@care/google-tag-manager';
