/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["draft"] }] */
import { merge } from 'lodash-es';
import produce from 'immer';
import { AppState } from '../types/app';
import { initialState as initialFlowState } from './flow/reducer';
import { initialState as initialEnterpriseState } from './enterprise/reducer';

/**
 * Makes any necessary changes to ensure the provided app state is compatible with the app
 * @param state app state (typically a version retrieved from local storage)
 */
export default function patch(state: AppState) {
  return produce(state, (draft) => {
    // flow is a new top-level key that may not exist yet on stored states
    if (!state.flow) {
      draft.flow = initialFlowState;
    }

    if (!state.enterprise) {
      draft.enterprise = initialEnterpriseState;
    } else {
      // apply defaults that might be missing in case of a change in shape of state from one version to another
      draft.enterprise = merge({}, initialEnterpriseState, draft.enterprise);
    }

    // migrate the `czenSessionId` state param to `czenJSessionId`
    if (!state.flow.czenJSessionId) {
      draft.flow.czenJSessionId = (state.flow as any).czenSessionId;
    }

    return draft;
  });
}
