import React from 'react';
import getConfig from 'next/config';
import useClientCustomizations from '@/components/hooks/useClientCustomizations';
import { DEFAULT_COBRAND_LOGO } from '@/constants';
import AppHeader, { AppHeaderProps } from './AppHeader';

const {
  publicRuntimeConfig: { CZEN_GENERAL, CZEN_RELATIVE_WPS_GROUPS_TRIAGE_PAGE_HANDLER },
} = getConfig();

export default function ClientHeader() {
  const { cobrandLogo, clientContactInformation } = useClientCustomizations();

  const { altText, clickable, imageURL } = cobrandLogo;
  const { email, phoneNumber } = clientContactInformation;

  const redirectUrl = imageURL ? CZEN_RELATIVE_WPS_GROUPS_TRIAGE_PAGE_HANDLER : '/';
  const imageFullURL = `${CZEN_GENERAL}/${imageURL || DEFAULT_COBRAND_LOGO}`;

  const appHeaderProps: AppHeaderProps = {
    cobrandLogo: {
      altText,
      imageURL: imageFullURL,
      clickable,
      redirectUrl,
    },
    contactUs: {
      email,
      phoneNumber,
    },
  };

  return <AppHeader {...appHeaderProps} />;
}
