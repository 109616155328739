import React, { ReactNode } from 'react';
import { Typography } from '@mui/material';

interface Props {
  children: ReactNode;
}

const Header = ({ children }: Props) => {
  return (
    <Typography variant="h2" sx={{ marginTop: 0 }}>
      {children}
    </Typography>
  );
};

export default Header;
