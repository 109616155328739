/* eslint-disable camelcase */
import { Location } from '@/components/shared/ZipInput';
import {
  getEnterpriseEnrollmentComponents_getEnterpriseEnrollmentComponents_customizableComponents,
  getEnterpriseEnrollmentComponents_getEnterpriseEnrollmentComponents,
} from '@/__generated__/getEnterpriseEnrollmentComponents';

import {
  getEnterpriseBrandedContent_getEnterpriseBrandedContent_cobrandLogo,
  getEnterpriseBrandedContent_getEnterpriseBrandedContent_clientContactInformation,
} from '@/__generated__/getEnterpriseBrandedContent';

export interface EnterpriseState {
  employeeId?: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  address: string;
  location: Location;
  email?: string;
  role?: string;
  error?: string;
  division?: string;
}

export enum ActionTypes {
  UPDATE_ENTERPRISE_STATE = 'UPDATE_ENTERPRISE_STATE',
}

export type EnterpriseAction = {
  type: ActionTypes.UPDATE_ENTERPRISE_STATE;
  data: Partial<EnterpriseState>;
};

export type CustomizableComponent =
  getEnterpriseEnrollmentComponents_getEnterpriseEnrollmentComponents_customizableComponents;
export type EnterpriseEnrollmentComponents =
  getEnterpriseEnrollmentComponents_getEnterpriseEnrollmentComponents;
export type CobrandLogo = getEnterpriseBrandedContent_getEnterpriseBrandedContent_cobrandLogo;
export type ClientContactInformation =
  getEnterpriseBrandedContent_getEnterpriseBrandedContent_clientContactInformation;
