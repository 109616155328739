import logger from '../lib/clientLogger';

const sessionStorageError = 'SessionStorage could not be used';
const testKey = 'ent-enrollment-session-key';
const testValue = 'testValue';
const errorTag = 'SessionStorageError';

const logSessionStorageError = (message = sessionStorageError) => {
  logger.warn(message, { tags: [errorTag] });
};

// eslint-disable-next-line import/prefer-default-export
export class SafeSessionStorage implements Storage {
  private static instance: SafeSessionStorage;

  private constructor() {
    this.sessionStorageAvailable = this.isAvailable();
    this.internalStorage = {};
    this.length = Object.keys(this.internalStorage).length;
  }

  isAvailable = (): boolean => {
    if (typeof window !== 'undefined') {
      if (window.sessionStorage) {
        try {
          window.sessionStorage.setItem(testKey, testValue);
          const result = window.sessionStorage.getItem(testKey);
          if (result === testValue) {
            return true;
          }
        } catch (e: any) {
          logSessionStorageError();
          return false;
        }
      }
    }
    logSessionStorageError();
    return false;
  };

  public static getInstance(): SafeSessionStorage {
    // We only want singletons when running client side
    // If this is ever ran server side (which it shouldn't I believe),
    // then create a new instance every time

    if (typeof window === 'undefined') {
      return new SafeSessionStorage();
    }

    if (!SafeSessionStorage.instance) {
      SafeSessionStorage.instance = new SafeSessionStorage();
    }

    return SafeSessionStorage.instance;
  }

  internalStorage: { [key: string]: string };

  sessionStorageAvailable: boolean;

  length: number;

  clear() {
    if (this.sessionStorageAvailable) {
      sessionStorage.clear();
      this.length = 0;
    } else {
      this.internalStorage = {};
      this.length = 0;
    }
  }

  getItem(key: string): string | null {
    if (this.sessionStorageAvailable) {
      return sessionStorage.getItem(key);
    }
    if (Object.prototype.hasOwnProperty.call(this.internalStorage, key)) {
      return this.internalStorage[key];
    }
    return null;
  }

  getObject(key: string): any {
    if (this.sessionStorageAvailable) {
      const item = sessionStorage.getItem(key);
      if (item !== null) {
        return JSON.parse(item);
      }
    }
    if (Object.prototype.hasOwnProperty.call(this.internalStorage, key)) {
      return JSON.parse(this.internalStorage[key]);
    }
    return null;
  }

  key(index: number): string | null {
    if (this.sessionStorageAvailable) {
      return sessionStorage.key(index);
    }
    const keys = Object.keys(this.internalStorage);
    if (keys.length > 0 && index < keys.length) {
      keys.sort();
      return keys[index];
    }
    return null;
  }

  removeItem(key: string): void {
    if (this.sessionStorageAvailable) {
      sessionStorage.removeItem(key);
      this.length = sessionStorage.length;
    } else {
      if (Object.prototype.hasOwnProperty.call(this.internalStorage, key)) {
        delete this.internalStorage[key];
      }
      this.length = Object.keys(this.internalStorage).length;
    }
  }

  setItem(key: string, value: string): void {
    if (this.sessionStorageAvailable) {
      sessionStorage.setItem(key, value);
      this.length = sessionStorage.length;
    } else {
      this.internalStorage[key] = value;
      this.length = Object.keys(this.internalStorage).length;
    }
  }

  setObject(key: string, value: any): void {
    if (this.sessionStorageAvailable) {
      sessionStorage.setItem(key, JSON.stringify(value));
      this.length = sessionStorage.length;
    } else {
      this.internalStorage[key] = JSON.stringify(value);
      this.length = Object.keys(this.internalStorage).length;
    }
  }
}
