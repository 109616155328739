import React from 'react';
import { Backdrop, BackdropProps, Box } from '@mui/material';
import { Loader } from '@care/react-component-lib';
import { HEIGHT_MINUS_TOOLBAR } from '../../constants';

interface OverlaySpinnerProps extends Partial<BackdropProps> {
  isOpen?: boolean;
  isTransparent?: boolean;
  spinnerSize?: string;
  wrapped?: boolean;
  className?: string;
  message?: string;
}

function OverlaySpinner(props: OverlaySpinnerProps) {
  const {
    isOpen = false,
    isTransparent = false,
    wrapped = false,
    className = '',
    message = '',
    ...backdropProps
  } = props;
  const backdrop = (
    <Backdrop
      classes={{ root: 'backdrop' }}
      sx={(theme) => ({
        '&.backdrop': {
          backgroundColor: theme.palette.background.default,
          zIndex: 5,
        },
      })}
      id="overlay-spinner"
      style={isTransparent ? { opacity: 0.5 } : {}}
      {...backdropProps}
      open={isOpen}>
      <Loader message={message} />
    </Backdrop>
  );
  if (wrapped) {
    return (
      <Box
        component="div"
        sx={{
          height: `${HEIGHT_MINUS_TOOLBAR}`,
        }}>
        {backdrop}
      </Box>
    );
  }
  return <>{backdrop}</>;
}

export default OverlaySpinner;
