import React from 'react';
import { AppFooter, AppFooterProps } from '@care/enterprise-components';
import useClientCustomizations from '@/components/hooks/useClientCustomizations';

export default function ClientFooter() {
  const { customizedFooterContent } = useClientCustomizations();

  const appFooterProps: AppFooterProps = {
    customizedFooterContent,
  };

  return <AppFooter {...appFooterProps} />;
}
