import { useEffect } from 'react';

const isClickable = (tagName: string) => ['A', 'BUTTON'].includes(tagName);
const isEnterKey = (key: string) => ['Enter', 'NumpadEnter'].includes(key);

const useEnterKey = (isButtonEnabled: boolean, callbackToNextPage: Function) => {
  const listener = ({ key, target: { tagName } }: any) => {
    if (isClickable(tagName)) return; // Allow default Enter functionality
    if (isEnterKey(key) && isButtonEnabled && tagName !== 'TEXTAREA') callbackToNextPage();
  };

  useEffect(() => {
    if (isButtonEnabled) document.addEventListener('keydown', listener);
    return () => document.removeEventListener('keydown', listener);
  }, [isButtonEnabled]);
};

export default useEnterKey;
