/*
 inspired from https://gist.github.com/kevinoid/a4a940a52b140bcc7dab9b67edab6dbf
 but modified to leverage some newer, sugary JS features as well as take into account polyfills that next.js provides
*/

// @ts-nocheck ... because some deprecated APIs are used in case an ancient browser comes along
import logger from './clientLogger';
import { CLIENT_SIDE_ERROR_TAG } from '../constants';

function errorEventToReport(errorOrEvent) {
  const windowEvent = window.event;
  let normalizedEvent = errorOrEvent;
  if (!errorOrEvent.error && !errorOrEvent.message && windowEvent && windowEvent.errorMessage) {
    normalizedEvent = {
      type: errorOrEvent.type,
      message: windowEvent.errorMessage,
      filename: windowEvent.errorUrl,
      lineno: windowEvent.errorLine,
      colno: windowEvent.errorCharacter,
    };
  }

  const error = normalizedEvent.error ?? normalizedEvent.cause ?? errorOrEvent;
  let errorString = error ? String(error) : null;
  if (
    error &&
    typeof error.message === 'string' &&
    errorString === Object.prototype.toString.call(error)
  ) {
    if (typeof error.name === 'string') {
      errorString = `${error.name}: ${error.message}`;
    } else {
      errorString = error.message;
    }
  }

  const eventMessage = normalizedEvent.message ? String(normalizedEvent.message) : null;
  const message = eventMessage ?? errorString ?? '';

  let stack = error && error.stack;
  if (stack) {
    const nlPos = stack.indexOf('\\n');
    const firstLine = nlPos > 0 ? stack.slice(0, nlPos) : null;
    if (firstLine === errorString) {
      stack = stack.slice(nlPos + 1);
    }
  }

  if (!stack) {
    if (normalizedEvent.filename) {
      stack = `    at ${normalizedEvent.filename}`;
      if (normalizedEvent.lineno) {
        stack += `:${normalizedEvent.lineno}`;
        if (normalizedEvent.colno) {
          stack += `:${normalizedEvent.colno}`;
        }
      }
    } else {
      try {
        throw new Error('Reported from');
      } catch (err) {
        ({ stack } = err);
      }
    }
  }

  return {
    type: errorOrEvent.type,
    message,
    stack,
  };
}

export function sendError(
  eventOrError: ErrorEvent | PromiseRejectionEvent | Error,
  overrideParams?: any
) {
  logger.error({
    tags: [CLIENT_SIDE_ERROR_TAG],
    error: errorEventToReport(eventOrError),
    // This is last to ensure we override any of the above
    ...overrideParams,
  });
}

export function sendWarningContainingError(
  eventOrError: ErrorEvent | PromiseRejectionEvent | Error,
  message: string,
  overrideParams?: any
) {
  logger.warn(message, { error: errorEventToReport(eventOrError), ...overrideParams });
}
