export enum LifeCareLoginPageVariants {
  RegisteredPassword = 'RegisteredPassword',
  OtpPassword = 'OtpPassword',
}

export enum ConfirmEligibilityPageVariants {
  NetNewUser = 'NetNewUser',
  LCRegisteredUser = 'LCRegisteredUser',
}

export enum GlobalHeaderPageVariants {
  Default = 'DefaultGlobalHeader',
}
export enum MemberEnrollmentRoute {
  WELCOME = 'welcome',
  PERSONAL_INFO = 'personal-info',
  ADDRESS = 'address',
  EMPLOYEE_ID = 'employee-id',
  SET_PASSWORD = 'set-password',
}
export const SKIP_AUTH_CONTEXT_KEY = 'skipAuth';
export const WINDOW_SENTRY_TRACE_TRANSACTION_KEY = 'SENTRY_TRACE_TRANSACTION';
export const BASE_PATH = '/app/ent-enrollment/';
export const memberEnrollment = '/member-enrollment';
export const enrollmentFlow = '/flow#';

export const ENTERPRISE_ROUTES = Object.freeze({
  CONFIRM_ELIGIBILITY: '/confirm-eligibility',
  ACCOUNT_CREATION: '/account-creation',
  VERTICAL_PREFERENCES: '/vertical-preferences',
  CONNECT_ACCOUNT: '/connect-account',
  LIFE_CARE_ACCOUNT_VERIFICATION: '/lc-account-verification',
  LIFE_CARE_LOGIN: '/lc-login',
  LIFE_CARE_FORGOT_PASSWORD: '/lc-forgot-password',
  WELCOME: `/${MemberEnrollmentRoute.WELCOME}`,
  DIRECT_ENROLLMENT_FLOW: '/flow',
  PERSONAL_INFO: `${enrollmentFlow}${MemberEnrollmentRoute.PERSONAL_INFO}`,
  ADDRESS: `${enrollmentFlow}${MemberEnrollmentRoute.ADDRESS}`,
  EMPLOYEE_ID: `${enrollmentFlow}${MemberEnrollmentRoute.EMPLOYEE_ID}`,
  SET_PASSWORD: `${enrollmentFlow}${MemberEnrollmentRoute.SET_PASSWORD}`,
  LIFECARE_AUTHENTICATION: '/lifecare-authentication',
});
export const BENEFITS_LOGIN = 'https://www.care.com/app/vhp/benefits-login';

// ~~ PLEASE ~~ update this document when modifying the `EventNames` enum
//    https://carecom.atlassian.net/wiki/spaces/CAR/pages/17365051027/Enterprise+MFE+Analytics+Properties
export enum EventNames {
  CTA_INTERACTED = 'CTA Interacted',
  LINK_INTERACTED = 'Link Interacted',
  SCREEN_VIEWED = 'Screen Viewed',
  ERROR_VIEWED = 'Error Viewed',
  MEMBER_EXISTS = 'MEMBER_EXISTS',
  BLOCKLIST_MATCH = 'BLOCKLIST_MATCH',
  NEED_HELP_CLICKED = 'Need Help Clicked',
  ENTERPRISE_LOGO_CLICKED = 'Enterprise Logo Clicked',
  PRIVACY_POLICY_CLICKED = 'Privacy Policy Clicked',
  TERMS_OF_USE_CLICKED = 'Terms of Use Clicked',
  MEMBER_ENROLLED = 'Member Enrolled',
  TOOL_TIP_INTERACTED = 'Tool Tip Interacted',
  SKIP_PASSWORD_SETTING = 'Skip For Now',
  MEMBER_CONNECTED = 'Member Connected',
  LOGIN = 'C@W Login',
  TEST_EXPOSURE = 'Test Exposure',

  // TODO the following are non-standard names, need to replace with standards when safe to do so
  ERROR_VIEWED_DEPRECATED = 'Error viewed',
  ENROLLMENT_FORM_CTA_CLICKED_DEPRECATED = 'Enrollment Form CTA clicked',
  CREATE_ACCOUNT_CTA_CLICKED_DEPRECATED = 'Create Account CTA clicked',
  CONNECT_MEMBER_CTA_CLICKED_DEPRECATED = 'Connect Member CTA clicked',
  CTA_INTERACTED_DEPRECATED = 'CTA interacted',
  CHILD_CARE_INTEREST_SELECTED_DEPRECATED = 'Child care Interest Selected',
  SUPPORTS_INTERNATIONAL = 'Supports International',
}

export enum HotjarEventNames {
  ULP_VIEWED = 'ent-universal-landing-page-viewed',
  WELCOME_PAGE_VIEWED = 'ent-welcome-page-viewed',
}

export enum LoginLocation {
  HEADER = 'Header',
  WELCOME_PAGE_EMAIL_ERROR = 'Welcome Page Email Error',
  ERROR_BANNER_ON_CREATE_ACCOUNT = 'Error Banner OnCreateAccount',
}

export const ROUTE_TO_SCREEN_NAME_MAP = Object.freeze({
  [`/[groupName]${ENTERPRISE_ROUTES.CONFIRM_ELIGIBILITY}`]: 'Enterprise Enrollment Page',
  [`/[groupName]${ENTERPRISE_ROUTES.ACCOUNT_CREATION}`]: 'Enterprise Create Account Page',
  [`/[groupName]${ENTERPRISE_ROUTES.VERTICAL_PREFERENCES}`]: 'Enterprise Vertical Preference Page',
  [`/[groupName]${ENTERPRISE_ROUTES.CONNECT_ACCOUNT}`]: 'Enterprise Connect Member Page',
  [`/[groupName]${ENTERPRISE_ROUTES.LIFE_CARE_ACCOUNT_VERIFICATION}`]:
    'LifeCare Account Verification',
  [`/[groupName]${ENTERPRISE_ROUTES.LIFE_CARE_LOGIN}`]: 'LifeCare Log in',
  [`/[groupName]${ENTERPRISE_ROUTES.LIFE_CARE_FORGOT_PASSWORD}`]: 'LifeCare Forgot Password',
  [`${memberEnrollment}${ENTERPRISE_ROUTES.WELCOME}`]: 'Enterprise Member Enrollment Welcome Page',
  [`${memberEnrollment}${ENTERPRISE_ROUTES.DIRECT_ENROLLMENT_FLOW}`]: 'Direct to Enrollment Flow',
});

export const DIRECT_ENROLLMENT_FLOW_STEP_NAMES = {
  PERSONAL_INFO: 'Enterprise Member Enrollment Personal Info Page',
  ADDRESS: 'Enterprise Member Enrollment Address Page',
  EMPLOYEE_ID: 'Enterprise Member Enrollment EmployeeId Page',
  SET_PASSWORD: 'Enterprise Member Enrollment Set Password Page',
};

export const DEFAULT_COBRAND_LOGO = 'img/cms/web/cobrandingLogo/cobrand_default.png';
export const CMS_PREFIX = 'https://www.care.com/img/cms/web/careForBusiness/directEnrollment';
export const TEST_COOKIE_KEY = 'n_tc';
export const CZEN_VISITOR_COOKIE_KEY = 'vc';
export const CZEN_SESSION_COOKIE_KEY = 'csc-session';
export const CZEN_JSESSIONID_COOKIE_KEY = 'JSESSIONID';
export const LOCAL_STORAGE_ERROR_TAG = 'LocalStorageError';
export const LOCAL_STORAGE_STATE_KEY = 'enrollment-mfe-state';
export const LOCAL_STORAGE_VERIFICATION_KEY = 'LocalStorageVerification';
export const LOCAL_STORAGE_VERIFICATION_VALUE = 'Verified';
export const CLIENT_SIDE_ERROR_TAG = 'ClientSideError';
export const CURRENT_STATE_MAJOR_VERSION = '2';
export const CURRENT_STATE_MINOR_VERSION = '0';
export const CURRENT_STATE_PATCH_VERSION = '0';
export const CURRENT_STATE_VERSION = `${CURRENT_STATE_MAJOR_VERSION}.${CURRENT_STATE_MINOR_VERSION}.${CURRENT_STATE_PATCH_VERSION}`;
// This constant considers paddings and margins as calc(100vh - (56px header + 24 + 24 paddings))
export const HEIGHT_MINUS_TOOLBAR = 'calc(100vh - 104px)';
export const ENROLLMENT_SESSION_ID_COOKIE_NAME = 'enrollment-session-id';
export const HELP_DESK_PHONE_NUMBER = '+1888-604-9565';
export const PAGE_VARIANT_DEFAULT = 'Default';
export const MIN_BIRTH_DATE = new Date(1900, 0, 1);
export const TRUSTED_VIDEO_URL = 'https://player.vimeo.com/video/822829385';
export const RATECARD_URL = '/seeker/upgradeMembershipPlan.do';

// at least 18 years old;
const today = new Date();
export const MAX_BIRTH_DATE = new Date(today.setFullYear(today.getFullYear() - 18));

export const TEXTS = Object.freeze({
  LABELS: {
    FIRST_NAME: 'First name',
    LAST_NAME: 'Last name',
    EMPLOYEE_ID: 'Employee ID',
    EMAIL: 'Email (work or personal)',
    EMAIL_OR_NAME: 'Email or screen name',
    DATE_OF_BIRTH: 'Date of birth',
    STREET_ADDRESS: 'Street address',
    ZIP_CODE: 'ZIP code',
    CONTINUE: 'Continue',
    PASSWORD: 'Password',
    CONFIRM_PASSWORD: 'Confirm Password',
    CREATE_ACCOUNT: 'Create account',
    CONNECT_ACCOUNT: 'Connect account',
    SKIP: 'Skip',
    SEND: 'Send',
    NEXT: 'Next',
    CHANGE_COUNTRY: 'change your country',
    EMAIL_ONLY: 'Email',
  },
  ERRORS: {
    REQUIRED: 'This is a required field',
    PASSWORD_DO_NOT_USE_NAME_CITY_EMAIL: "Don't use your name, city or email for password",
    PASSWORD_DO_NOT_USE_KEYWORDS_LIKE_PASSWORD:
      "Please don't use keywords like password in your password",
    PASSWORD_DO_NOT_USE_REPEATING_SEQUENCE:
      "Don't use a repeating sequence of 1,2,3 or 4 characters for your password",
    PASSWORD_CAN_NOT_EXCEED: 'Cannot exceed 30 characters',
    PASSWORDS_MUST_MATCH: 'Passwords must match',
    PASSWORD_CHANGE_ERROR: 'There was an issue updating your password. Please try again',
    PASSWORD_INVALID: 'The passcode you entered is invalid.',
    ENTER_A_VALID_FIRST_NAME: 'Enter a valid first name.',
    ENTER_A_VALID_LAST_NAME: 'Enter a valid last name.',
    ENTER_A_VALID_EMAIL: 'Enter a valid email',
    SELECT_A_VALID_DATE: 'Please select a valid date',
    STREET_ADDRESS_CAN_NOT_EXCEED: 'Cannot exceed 55 characters',
    REQUEST_FAILED: 'Sorry, we were unable to complete your request',
    NO_SPECIAL_CHARACTERS: 'No special characters or numbers allowed. Please try again',
    FIELD_CHARACTER_MAX: (name: string, max: number) => `${name} must be ${max} characters or less`,
    FIELD_CHARACTER_MIN: (name: string, min: number) =>
      `${name} must be at least ${min} characters`,
    MUST_CONTAIN_LETTER: (name: string) => `${name} must contain at least one letter`,
    TOO_YOUNG_TO_SIGN_UP: 'You must be at least 18 years old to use Care.com',
    IS_REQUIRED: (name: string) => `${name} is required`,
    UNEXPECTED_ERROR: 'An unexpected error has occurred. Refresh and try again',
    ELIGIBILITY_FAILURE: `Oops! Eligibility check failed`,
    EMAIL_ALREADY_EXISTS: 'This email is already registered.',
    EMPLOYER_NAME_INVALID: `We can't find your employer. Try again or reach out to your benefits administrator`,
    SERVER_ERROR_GENERIC: `Something went wrong. Please refresh the page to try again`,
    ELIGIBILITY_FAILURE_SUBTEXT:
      'It looks like you may not be eligible for this benefit. Contact your benefits administrator for assistance.',
    EMPLOYEE_ID_TAKEN: 'This Employee ID is already taken.',
    COUNTRY_REQUIRED: 'Country is required',
  },
  STATIC: {
    HEADER: {
      NEED_HELP: 'Need help?',
      HELP_CENTER: 'Help center',
      PAGE_VARIANTS: GlobalHeaderPageVariants,
    },
    ELIGIBILITY: {
      HEADER: `Help us confirm you’re eligible`,
      ERROR_BANNER_HEADER: 'Oops! Eligibility check failed.',
      SSO_HEADER: `Complete your account information`,
      LOGGING: {
        PAGE_VARIANTS: ConfirmEligibilityPageVariants,
      },
    },
    TERMS_OF_USE: {
      CONTENT:
        'You consent to receiving email communications from Care.com and may opt out from receiving such emails by changing your preferences, or as detailed in our terms.',
    },
    CONNECT_ACCOUNT: {
      HEADER: 'Connect your Care.com account',
      SUBHEADER: 'Enter the login for your existing account',
      BLUE_BANNER_HEADER: 'This email already has a Care account',
      BLUE_BANNER_TEXT_START:
        'You can either link the account to your employee-sponsored program below, or',
      BLUE_BANNER_LINK: 'enroll with a different email',
      BLUE_BANNER_TEXT_END: 'using a different email',
      ERROR_BANNER_HEADER: 'Oops! Account connection failed',
      ERROR_BANNER_LINK: 'reset password',
      FORGOT_PASSWORD: 'Forgot password?',
      CREATE_ACCOUNT_DIFFERENT_EMAIL: 'Create account with a different email',
    },
    ACCOUNT_CREATION: {
      HEADER: 'Create your Care benefits account',
      PASSWORD_HELPER: 'At least 6 characters',
      ERROR_BANNER_HEADER: 'Oops! Account creation failed.',
      ACCOUNT_CREATED: 'Account created!',
    },
    VERTICAL_PREFERENCES: {
      HEADER: 'What kinds of care might your family need?',
      PREFERENCES: {
        CHILD_CARE: 'Child care',
        ADULT_CARE: 'Adult care',
        HOUSEKEEPING: 'Housekeeping',
        PET_CARE: 'Pet care',
        SPECIAL_NEEDS: 'Special needs',
        TUTORING: 'Tutoring',
      },
    },
    LIFE_CARE_ACCOUNT_VERIFICATION: {
      HEADER: 'Let’s start by looking up your benefits status',
      EMAIL_SUBHEADER:
        'Enter the email associated with your LifeCare account. New enrollees enter your work email.',
      EMPLOYEE_ID_SUBHEADER: 'Enter your Employee ID',
    },
    LIFE_CARE_LOGIN: {
      HEADER: 'Great! We found your LifeCare account',
      SUBHEADER: 'Log in so we can transition your benefits.',
      FORGOT_PASSWORD: 'Forgot password?',
      WRONG_CREDENTIALS: 'Wrong email or password',
      MODAL_CONTENT: `Please contact our member services team at ${HELP_DESK_PHONE_NUMBER} to reset your password.`,
      LOGGING: {
        PAGE_VARIANTS: LifeCareLoginPageVariants,
      },
    },
    ONETIME_PASSCODE_LOGIN: {
      HEADER: (email: string) => `We sent a one-time passcode to ${email}`,
      SUBHEADER:
        'Please check your email and enter the code below. This passcode will expire in 20 minutes.',
      PLACEHOLDER: 'One-time passcode',
    },
    FORGOT_PASSWORD: {
      HEADER: 'Forgot your LifeCare password?',
      SUBHEADER: `No problem. Just enter the email address that is currently linked to your account and we'll send you a one time passcode to use as your password.`,
    },
    PAGE_NOT_FOUND: {
      HEADER: 'Page not found',
      BODY_1:
        "We're sorry, there was a problem accessing the page that you requested. The page may have been removed, changed in name, or is unavailable at the moment.",
      BODY_2:
        'Please check the address in your browser window and make sure everything is spelled correctly.',
    },
  },
  TOOLTIPS: {
    BIRTH_DATE: 'Your birth date is required to help confirm your identity.',
  },
  HELPER_TEXT: {
    PASSWORD_LENGTH: 'At least 8 characters in length',
  },
  ONE_CLICK_ENROLLMENT: {
    STREET_ADDRESS: '1501 S MoPac Expy #340',
    ZIP_CODE: '78746',
    ELIGIBILITY_FAILURE:
      'It looks like you may not be eligible for this benefit. Contact your benefits administrator for assistance.',
    VERIFY_INFO_HEADING_SUFFIX: 'has partnered with Care',
    VERIFY_INFO_SUBHEADING: 'Enter your name to create your free account',
    VERIFY_INFO_STEP_NAME: 'One-click personal info page',
    WELCOME_LANDING_PAGE_STEP_NAME: 'One-click welcome page',
    ONE_CLICK_ENROLL_EVENT_NAME: 'One-Click Enroll Initiated',
    ONE_CLICK_ENROLL_V2_EVENT_NAME: 'One-Click Enroll v2 Initiated',
  },
  EMPLOYEE_ID_HEADER_TEST: {
    HEADER: "Let's confirm your eligibility",
  },
  SSO_DIRECT_REGISTRATION: {
    VERIFY_EMAIL_HEADING: 'What is your email address?',
    MISSING_EMAIL_ADDRESS_PAGE_EVENT: 'Enterprise Member Enrollment Missing Email Address Page',
  },
});

export enum DateTimeFormat {
  YEAR_MONTH_DAY = 'YYYY-MM-DD',
  MONTH_DAY_YEAR = 'MM/DD/YYYY',
  DAY = 'ddd',
  MONTH_DAY = 'MMM D',
  TIME_AM_PM = 'h:mm A',
  TIME_TWENTY_FOUR_HOUR = 'HH:mm',
}

export const ENT_EMPLOYEE_ID_HEADER_TEST_VARIANTS = {
  CONTROL: 'Control',
  TEST: 'Test',
  HOLDOUT: 'Holdout',
};

export interface FeatureFlag {
  key: string;
  defaultValue: boolean | any;
  requireGroupName?: boolean;
}

export const FEATURE_FLAGS: {
  [key: string]: FeatureFlag;
} = Object.freeze({
  ACCESSIBE_ENABLED_LD_FLAG: {
    key: 'accessibe-enabled',
    defaultValue: false,
    requireGroupName: false,
  },
  ENT_REDIRECT_LD_FLAG: {
    // EXCLUSION LIST
    key: 'ent-direct-enrollment-redirect-v2',
    defaultValue: {},
    requireGroupName: false,
  },
  ENT_LIFECARE_TRANSITION_FLAG: {
    key: 'lifecare-transition-clients',
    defaultValue: false,
    requireGroupName: true,
  },
  ENT_LIFECARE_AUTO_ENROLLMENT_FLAG: {
    key: 'enterprise-lc-auto-enrollment',
    defaultValue: false,
    requireGroupName: true,
  },
  ENT_ONE_CLICK_ENROLLMENT_FLAG: {
    key: 'ent-one-click-enroll-ab-test',
    defaultValue: false,
    requireGroupName: true,
  },
  ENT_EMPLOYEE_ID_HEADER_EXCLUSION_FLAG: {
    // EMPLOYEE HEADER ID EXCLUSION LIST
    key: 'ent-enrollment-employeeId-header-exclusion',
    defaultValue: {},
    requireGroupName: false,
  },
  ENT_EMPLOYEE_ID_HEADER_TEST_FLAG: {
    key: 'ent-enrollment-employeeId-header-ab-test',
    defaultValue: ENT_EMPLOYEE_ID_HEADER_TEST_VARIANTS.HOLDOUT,
    requireGroupName: false,
  },
  ENT_ONE_CLICK_ENROLLMENT_V2_FLAG: {
    key: 'ent-one-click-enroll-v2-enabled',
    defaultValue: false,
    requireGroupName: false,
  },
  ENT_LIFECARE_TRANSITION_D2E_CLIENT_FLAG: {
    key: 'ent-lifecare-transition-d2e-client',
    defaultValue: {},
    requireGroupName: false,
  },
  GTM_ENABLED: {
    key: 'gtm-enabled',
    defaultValue: false,
  },
  GTM_JS_URL: {
    key: 'gtm-js-url',
    defaultValue: '',
  },
  TTP_ASSET_URL: {
    key: 'ttp-asset-url',
    defaultValue: '',
  },
  TTP_FAVICON_URL: {
    key: 'ttp-favicon-url',
    defaultValue: '',
  },
  ENT_SSO_PLUS_DIRECT_REGISTRATION_FLAG: {
    key: 'ent-sso-d2e-enabled',
    defaultValue: false,
  },
  ENT_UTM_REDIRECT_FLAG: {
    key: 'ent-utm-redirect',
    defaultValue: false,
  },
  SAFETY_SIFT_OLD_CONSOLE_ENABLED: {
    key: 'safety-sift-science-enabled',
    defaultValue: false,
  },
  SAFETY_SIFT_USER_JOURNEY_ENABLED: {
    key: 'safety-sift-user-journey-enabled',
    defaultValue: false,
  },
  ENT_ENROLLMENT_DIV_DROPDOWN: {
    key: 'ent-enrollment-div-dropdown',
    defaultValue: false,
    requireGroupName: true,
  },
  ENT_VOLUNTARY_BENEFITS_EVERGREEN_ENABLED: {
    key: 'ent-evergreen-vb',
    defaultValue: false,
  },
});
