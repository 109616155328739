/* eslint-disable no-param-reassign */
import React from 'react';
import { InputLabel, MenuItem, FormControl, FormHelperText } from '@mui/material';
import { Select as CareSelect } from '@care/react-component-lib';
import { SelectInputProps } from '@mui/material/Select/SelectInput';
import { SxClassProps } from '@/types/global';

const aggregateCSSClasses = (cssClasses: string[]): string =>
  cssClasses.map((className) => `& .${className}`).join(',');

// Classes inside an input wrapper that make sense to be aggregated
const cssClassNames = [
  'MuiInputBase-root',
  'MuiInputAdornment-positionStart',
  'MuiInputAdornment-positionEnd',
];

const cssRepeatedClasses = aggregateCSSClasses(cssClassNames);
const classes: SxClassProps = {
  // a workaround needed until the DNA-1169 is done
  selectContainer: (theme) => ({
    width: '100%',
    marginBottom: 0,
    paddingBottom: 2,
    '& .MuiInputAdornment-positionEnd': {
      margin: 0,
    },
    '& .MuiInputBase-root': {
      height: '64px',
      '&:hover': {
        backgroundColor: theme.palette.care.grey[200],
        '@media (hover: none)': {
          backgroundColor: theme.palette.care.transparent,
        },
      },
      '&:focus-within': {
        backgroundColor: `${theme.palette.care.transparent} !important`,
      },
    },
    '&.MuiInput-underline': {
      '@media (hover: none)': {
        '&:before, &:after': {
          content: 'none',
        },
      },
    },
    '& .MuiSelect-select': {
      padding: '0',
      paddingTop: 2.25,
      paddingBottom: 0,
      paddingLeft: 2,
      backgroundColor: theme.palette.care.transparent,
      color: theme.palette.care.navy[900],
      fontSize: '18px',
      fontWeight: 700,
      cursor: 'pointer',
      placeHolder: {
        color: theme.palette.care.grey[600],
        fontWeight: 'normal',
        fontSize: '16px',
      },
      '&[disabled]': {
        pointerEvents: 'none',
        color: theme.palette.care.grey[600],
      },
      '&:focus': {
        backgroundColor: theme.palette.care.transparent,
      },
      '&:-webkit-autofill': {
        WebkitBoxShadow: '0 0 0 1000px white inset',
        transition: 'background-color 5000s ease-in-out 0s',
      },
    },
    [cssRepeatedClasses]: {
      marginTop: 0,
    },
    '& .MuiFormHelperText-root': {
      marginTop: 0.5,
      paddingLeft: 0.25,
    },
    '& .MuiFormHelperText-root.Mui-disabled': {
      color: theme.palette.care.grey[500],
    },
    '& .MuiInputLabel-root': {
      zIndex: 1,
      transform: 'translate(9px, 44px)',
      color: theme.palette.care.grey[700],
      textTransform: 'none',
      fontWeight: 'normal',
      width: '0',
      '&:hover + .MuiInputBase-root': {
        backgroundColor: theme.palette.care.grey[200],
        '@media (hover: none)': {
          backgroundColor: theme.palette.care.transparent,
        },
      },
      '&.Mui-disabled': {
        color: theme.palette.care.grey[500],
      },
      '&.MuiFormLabel-filled': {
        fontSize: '14px',
        transform: 'translate(9px, 29px)',
        '& + div .MuiSelect-select': {
          paddingTop: '28px !important',
        },
      },
    },
    '&:focus-within .MuiInputLabel-root': {
      fontSize: '14px',
      color: theme.palette.care.grey[700],
      transform: 'translate(9px, 29px)',
    },
  }),
};

export interface SelectOption {
  label: string;
  value: string;
}
export interface SelectProps extends SelectInputProps {
  id: string;
  name: string;
  label: string;
  options: SelectOption[];
  helperText?: string;
}

const Select = ({ id, value, label, options, helperText, error, ...other }: SelectProps) => {
  return (
    <FormControl error={error} sx={classes.selectContainer}>
      <CareSelect
        id={id}
        value={value}
        error={error}
        displayEmpty
        inputProps={{
          'data-testid': `${label}-select`,
        }}
        {...other}>
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            sx={{
              paddingLeft: 0,
            }}>
            {option.label}
          </MenuItem>
        ))}
      </CareSelect>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default Select;
