import { DirectEnrollment } from '@/types/directEnrollment';
import { SafeSessionStorage } from '@/utilities/sessionStorageHelper';

export const DIRECT_ENROLLMENT_STORAGE_KEY = 'directEnrollment';
export const defaultData: DirectEnrollment = {
  groupShortName: '',
  groupDisplayName: '',
  firstName: '',
  lastName: '',
  dateOfBirth: null,
  address: '',
  zipCode: '',
  email: '',
  password: '',
  workEmail: null,
  employeeId: null,
  lifeCareMemberId: null,
  utmRedirectFlagEnabled: false,
  wpsEnrollmentStatus: null,
};

const trimStringValues = (values: Partial<DirectEnrollment>) => {
  return Object.entries(values).reduce((trimmedValues, [k, v]) => {
    return { ...trimmedValues, [k]: typeof v === 'string' ? v.trim() : v };
  }, {} as Partial<DirectEnrollment>);
};

const useDirectEnrollmentData = () => {
  const storage = SafeSessionStorage.getInstance();

  const fetch = (): DirectEnrollment => {
    return storage.getObject(DIRECT_ENROLLMENT_STORAGE_KEY) || defaultData;
  };

  const update = (val: Partial<DirectEnrollment>) => {
    // Ensure that no string values are stored with white-space on either end
    const trimmedValues = trimStringValues(val);
    storage.setObject(DIRECT_ENROLLMENT_STORAGE_KEY, { ...fetch(), ...trimmedValues });
  };

  const clear = () => {
    storage.clear();
  };
  return { fetch, update, clear };
};

export default useDirectEnrollmentData;
