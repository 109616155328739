import { User, UserManager, WebStorageStateStore } from 'oidc-client';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { SafeLocalStorage } from '@/utilities/localStorageHelper';
import { sendError } from './ClientErrorHandler';

const { publicRuntimeConfig } = getConfig();

interface ConstantsProps {
  OIDC_STS_AUTHORITY: string;
  OIDC_CLIENT_ID: string;
  OIDC_RESPONSE_TYPE: string;
  OIDC_CLIENT_SCOPE: string;
  OIDC_CALLBACK_PATH: string;
  OIDC_LOGOUT_URL: string;
}

export interface Authenticable {
  getUser(): Promise<User | null> | null;

  login(authToken?: string): Promise<void>;

  redirectLogin(redirectUri: string, authToken?: string): Promise<void>;

  renewToken(): Promise<User>;

  getStore(): any;

  resetUserData(): void;

  logout(): Promise<void>;

  validToken(): any;

  silentBackendAuth(): Promise<boolean>;
}

// TODO: remove this module once the shared auth module has been built:
// https://jira.infra.carezen.net/browse/DNA-51
export default function AuthService(): Authenticable {
  let userManager: UserManager;
  let clientStorage: SafeLocalStorage;
  const {
    OIDC_STS_AUTHORITY,
    OIDC_CLIENT_ID,
    OIDC_RESPONSE_TYPE,
    OIDC_CLIENT_SCOPE,
    OIDC_CALLBACK_PATH,
    OIDC_LOGOUT_URL,
  }: ConstantsProps = publicRuntimeConfig;

  const router = useRouter();

  const clientStorageKey = `czen.oidc.user:${OIDC_STS_AUTHORITY}:${OIDC_CLIENT_ID}`;

  if (typeof window !== 'undefined') {
    clientStorage = SafeLocalStorage.getInstance();
    if (
      !OIDC_STS_AUTHORITY ||
      !OIDC_CLIENT_ID ||
      !OIDC_RESPONSE_TYPE ||
      !OIDC_CLIENT_SCOPE ||
      !OIDC_CALLBACK_PATH ||
      !OIDC_LOGOUT_URL
    ) {
      throw new Error('AuthService: Missing required props');
    }

    // construct a callback URL that's relative to the browser's current origin
    const origin = `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? `:${window.location.port}` : ''
    }`;
    const fullCallbackUrl = `${origin}${OIDC_CALLBACK_PATH}`;
    const settings: object = {
      authority: OIDC_STS_AUTHORITY,
      clearHashAfterLogin: false,
      client_id: OIDC_CLIENT_ID,
      redirect_uri: fullCallbackUrl,
      post_logout_redirect_uri: OIDC_LOGOUT_URL,
      response_type: OIDC_RESPONSE_TYPE,
      scope: OIDC_CLIENT_SCOPE,
      userStore: new WebStorageStateStore({
        prefix: 'czen.oidc.',
        store: clientStorage,
      }),
    };

    userManager = new UserManager(settings);
  }

  const getUser = () => {
    if (userManager) {
      return userManager.getUser();
    }
    return null;
  };

  const login = (authToken?: string) => {
    const redirectUri = document.location.pathname + document.location.search;
    return userManager.signinRedirect({
      data: redirectUri,
      extraQueryParams: {
        authToken,
      },
    });
  };

  const renewToken = () => {
    return userManager.signinSilent();
  };
  const getStore = () => {
    const item = clientStorage.getItem(clientStorageKey);
    return item === null ? null : JSON.parse(item);
  };
  const removeStore = (key: string) => {
    clientStorage.removeItem(key);
  };

  const resetUserData = () => {
    userManager.removeUser().catch((reason) => {
      sendError(reason);
    });
    removeStore(clientStorageKey);
  };

  const logout = () => {
    const idToken = getStore() && getStore().id_token;
    userManager.clearStaleState();
    return userManager
      .signoutRedirect({
        id_token_hint: idToken,
      })
      .catch((reason) => {
        sendError(reason);
      });
  };
  const validToken = () => {
    const tokenInfo = getStore();
    if (tokenInfo === null || tokenInfo.expires_at === null) {
      return false;
    }
    return tokenInfo.expires_at > Date.now() / 1000;
  };

  const redirectLogin = (redirectUri: string, authToken?: string) => {
    return userManager.signinRedirect({
      data: redirectUri,
      extraQueryParams: {
        authToken,
      },
    });
  };

  const silentBackendAuth = async () => {
    const authResponse = await fetch(`${router.basePath}/api/auth`);
    if (authResponse.status !== 200) {
      return false;
    }

    const token = await authResponse.json();

    // manually calculate `expires_at` in the same way as `oidc-client` since it isn't by the `User` constructor
    // and the property is marked as readonly in the User typedef
    // https://github.com/IdentityModel/oidc-client-js/blob/1.10.1/src/User.js#L26-L32
    if (typeof token.expires_in === 'number' && token.expires_in > 0) {
      const now = Math.floor(Date.now() / 1000);
      token.expires_at = now + token.expires_in;
    }

    const user = new User(token);
    await userManager.storeUser(user);

    return true;
  };

  return {
    login,
    logout,
    redirectLogin,
    getUser,
    getStore,
    renewToken,
    resetUserData,
    validToken,
    silentBackendAuth,
  };
}
