var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"ent-enrollment-mfe@2.4.0"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/s/o/ent-enrollment-mfe/v2.4.0/021c0826b8d3c55f3e6e02e09dcbfa003e7e37ca";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { Offline as OfflineIntegration } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';
import { WINDOW_SENTRY_TRACE_TRANSACTION_KEY } from '@/constants';
import initSentry from './sentry.init';

initSentry({
  integrations: [new Integrations.BrowserTracing(), new OfflineIntegration()],
  tracesSampler: (samplingContext) => {
    // always sample requests where the parent is being sampled
    if (samplingContext.parentSampled) {
      return true;
    }

    return window[WINDOW_SENTRY_TRACE_TRANSACTION_KEY];
  },
});
