import { SafeLocalStorage } from '@/utilities/localStorageHelper';
import { UserState } from '@/types/user';

export default function useGoogleSignInData() {
  const clientStorage = SafeLocalStorage.getInstance();
  const googleFirstName = clientStorage.getItem('googleFirstName') ?? undefined;
  const googleLastName = clientStorage.getItem('googleLastName') ?? undefined;
  const googleEmail = clientStorage.getItem('googleEmail') ?? undefined;
  const googleIdToken = clientStorage.getItem('googleIdToken') ?? undefined;

  const userState: Partial<UserState> = {
    firstName: googleFirstName,
    lastName: googleLastName,
    email: googleEmail,
    googleIdToken,
  };

  return userState;
}
