import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { styled, Theme, useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Transition, SwitchTransition } from 'react-transition-group';
import {
  TransitionStatus,
  EXITED,
  ENTERING,
  ENTERED,
  EXITING,
} from 'react-transition-group/Transition';

import usePageTransition from './usePageTransition';

const FINISHED = 'finished';

type TransitionState = TransitionStatus | typeof FINISHED;

interface PageTransitionProps {
  children: ReactElement;
}

interface TransformAnimation {
  state: TransitionState;
}
interface TransitionBoxProps extends TransformAnimation {
  theme: Theme;
}

export const TRANSLATE_3D_POSITION = {
  NONE: 'initial',
  RIGHT: 'translateX(150%) translateY(0)',
  CENTER: 'translateX(0) translateY(0)',
  LEFT: 'translateX(-150%) translateY(0)',
};

export const getTransformAnimation = ({ state }: TransformAnimation): string => {
  let transformString = '';

  switch (state) {
    case ENTERING:
      transformString = TRANSLATE_3D_POSITION.RIGHT;
      break;
    case ENTERED:
      transformString = TRANSLATE_3D_POSITION.CENTER;
      break;
    case EXITING:
      transformString = TRANSLATE_3D_POSITION.LEFT;
      break;
    case EXITED:
      transformString = TRANSLATE_3D_POSITION.LEFT;
      break;
    case FINISHED:
      transformString = TRANSLATE_3D_POSITION.NONE;
      break;
    default:
      transformString = TRANSLATE_3D_POSITION.CENTER;
      break;
  }

  return transformString;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TransitionBox = styled(({ state, ...other }: any) => <Box {...other} />)(
  ({ theme: currentTheme, state }: TransitionBoxProps) => ({
    transition: currentTheme.transitions.create(['transform', 'opacity'], {
      duration: currentTheme.transitions.duration.standard,
    }),
    transform: getTransformAnimation({ state }),
    opacity: state === ENTERED || state === FINISHED ? '1' : '0',
  })
);

type CustomBoxProps = {
  transitionState: TransitionState;
  children: ReactNode;
};
function CustomBox(props: CustomBoxProps) {
  const { transitionState, children } = props;
  const [currentTransitionState, setCurrentTransitionState] = useState(transitionState);
  const theme = useTheme();

  useEffect(() => {
    let timeoutId: any;
    setCurrentTransitionState(transitionState);
    if (transitionState === ENTERED) {
      // set custom transition state
      timeoutId = setTimeout(() => {
        setCurrentTransitionState(FINISHED);
      }, theme.transitions.duration.standard);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [transitionState]);

  return (
    <TransitionBox theme={theme} state={currentTransitionState}>
      {children}
    </TransitionBox>
  );
}

const PageTransition = ({ children }: PageTransitionProps) => {
  const theme = useTheme();
  const { isTransitionDisabled } = usePageTransition(children);

  if (isTransitionDisabled) {
    return <>{children}</>;
  }

  return (
    <SwitchTransition mode="out-in">
      <Transition
        key={children?.key}
        timeout={theme.transitions.duration.standard}
        unmountOnExit
        mountOnEnter>
        {(state: TransitionStatus) => {
          return <CustomBox transitionState={state}>{children}</CustomBox>;
        }}
      </Transition>
    </SwitchTransition>
  );
};

export default PageTransition;
