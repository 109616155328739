import React from 'react';
import { FieldConfig, useField } from 'formik';
import Select, { SelectProps } from '@/components/shared/Select';

type FormikInlineSelectFieldProps = SelectProps & FieldConfig<string>;

function FormikInlineSelectField(props: FormikInlineSelectFieldProps) {
  const { name, validate, ...rest } = props;
  const [field, meta] = useField({ name, validate });
  const { helperText } = props;
  const showError = meta.touched && Boolean(meta.error);

  return (
    <Select
      {...field}
      {...rest}
      error={showError}
      helperText={showError ? meta.error : helperText}
    />
  );
}

FormikInlineSelectField.defaultProps = {
  helperText: undefined,
};

export default FormikInlineSelectField;
