import { LDEvaluationDetail, LDFlagValue } from 'launchdarkly-node-server-sdk';
import React, { createContext, useContext, ReactNode, useState } from 'react';

interface FeatureFlagsProviderProps {
  children: ReactNode;
  flags: FeatureFlags | undefined;
}

export interface FeatureFlag {
  key: string;
  defaultValue: LDFlagValue;
  requireGroupName?: boolean;
}
export type FeatureFlagsState = {
  flags: FeatureFlags | undefined;
};

const initialState = {
  flags: {},
};

export type FeatureFlags = Record<string, LDEvaluationDetail>;

const FeatureFlagsContext = createContext<FeatureFlagsState>(initialState);

export const FeatureFlagsProvider = ({ children, flags }: FeatureFlagsProviderProps) => {
  const [state] = useState({ flags });
  return <FeatureFlagsContext.Provider value={state}>{children}</FeatureFlagsContext.Provider>;
};

export const useFeatureFlags = () => useContext(FeatureFlagsContext);
export const useFlag = (flagName: string): boolean | any => {
  const { flags } = useFeatureFlags();
  const flag = flags && flags[flagName];
  return flag?.value;
};
